import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

import DataItem from "./DataItem";
import { Helmet } from "react-helmet";

import GalleryCard3 from "../components/gallery-card3";
import "./showcase.css";

const Showcase = (props) => {
  const [noOfEleme, setNoOfEleme] = useState(12);
  const slice = DataItem.items.slice(0, noOfEleme);
  const seemore = () => {
    if(noOfEleme>DataItem.items.length){

    }else {
      setNoOfEleme(noOfEleme + noOfEleme);
    }
  };

  const seeItemShowCase = slice.map((item) => {
    return (
      <a
        href={item.href}
        target="_blank"
        rel="noreferrer noopener"
        key={item.id}
      >
        <GalleryCard3
          title={item.title}
          subtitle={item.subtitle}
          image_src={item.image_src}
          rootClassName="rootClassName12"
          className="showcase-component"
        ></GalleryCard3>
      </a>
    );
  });

  return (
    <div className="showcase-container">
      <Helmet>
        <title>Actif3D - Your Creator Economy Assets</title>
        <meta
          name="description"
          content="A comprehensive platform for creating, trading and playing with 3D/VR/AR assets"
        />
        <meta
          property="og:title"
          content="Actif3D - Your Creator Economy Assets"
        />
        <meta
          property="og:description"
          content="A comprehensive platform for creating, trading and playing with 3D/VR/AR assets"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d75c0f3b-381e-4572-861d-fc34765a8ad7/11c1d650-58f6-41cb-aee8-933ba0523fbe?org_if_sml=1"
        />
      </Helmet>
      <header data-thq="thq-navbar" className="showcase-navbar">
        <Link to="/" className="showcase-navlink">
          <img alt="image" src="/logow.svg" className="showcase-branding" />
        </Link>
        <div data-thq="thq-burger-menu" className="showcase-burger-menu">
          <div className="showcase-hamburger">
            <img alt="image" src="/hamburger.svg" className="showcase-icon" />
          </div>
        </div>
        <div data-thq="thq-mobile-menu" className="showcase-mobile-menu">
          <div
            data-thq="thq-mobile-menu-nav"
            data-role="Nav"
            className="showcase-nav"
          >
            <div className="showcase-container1">
              <Link to="/" className="showcase-navlink01">
                <img alt="image" src="/logow.svg" className="showcase-image" />
              </Link>
              <div data-thq="thq-close-menu" className="showcase-menu-close">
                <svg viewBox="0 0 1024 1024" className="showcase-icon1">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav
              data-thq="thq-mobile-menu-nav-links"
              data-role="Nav"
              className="showcase-nav1"
            >
              <Link to="/about" className="showcase-navlink02">
                About
              </Link>
              <a href="https://academy.actif3d.com/" className="showcase-link">
                Academy
              </a>
              <a
                href="https://pay.actif3d.com"
                target="_blank"
                rel="noreferrer noopener"
                className="showcase-link1"
              >
                Pricing
              </a>
              <Link to="/showcase" className="showcase-navlink03">
                Showcase
              </Link>
              <Link to="/download" className="showcase-navlink04">
                Download
              </Link>
            </nav>
            <div className="showcase-container2">
              <a
                href="https://id.actif3d.com/signin/oauth?response_type=code&amp;client_id=actif3d&amp;redirect_uri=https://actif3d.com/auth&amp;scope=openid%20profile%20email%20name"
                className="showcase-login button"
              >
                Login
              </a>
              <a
                href="https://id.actif3d.com/signup"
                className="showcase-register button"
              >
                Register
              </a>
            </div>
          </div>
        </div>
      </header>
      <div className="showcase-gallery">
        <h2 className="showcase-heading">Showcase</h2>
        <span className="showcase-text">
          Beautiful contents from Actif3D Professional Network
        </span>
        <div className="showcase-container3">{seeItemShowCase}</div>
        <Link
          to={noOfEleme<DataItem.items.length?"/showcase":"/vlu"}
          className="showcase-navlink05 button"
          onClick={() => seemore()}
        >
          See more...
        </Link>
      </div>
      <div className="showcase-footer">
        <div className="showcase-content">
          <div className="showcase-main">
            <div className="showcase-branding1">
              <Link to="/" className="showcase-navlink06">
                <img alt="image" src="/logow.svg" className="showcase-image1" />
              </Link>
              <span className="showcase-text1">
                Your Creator Economy Assets
              </span>
            </div>
            <div className="showcase-links">
              <div className="showcase-column">
                <span className="showcase-header">PRODUCTS &amp; SERVICES</span>
                <div className="showcase-list">
                  <Link to="/download" className="showcase-navlink07">
                    Actif3D Editor
                  </Link>
                  <Link to="/" className="showcase-navlink08">
                    Cloud Hosting
                  </Link>
                  <a
                    href="https://editor.actif3d.com"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="showcase-link3"
                  >
                    Cloud Baking
                  </a>
                  <Link to="/showcase" className="showcase-navlink09">
                    <span className="showcase-text2">Showcase</span>
                    <br></br>
                  </Link>
                </div>
              </div>
              <div className="showcase-column1">
                <span className="showcase-header1">SUPPORTS</span>
                <div className="showcase-list1">
                  <a
                    href="https://academy.actif3d.com"
                    className="showcase-link4"
                  >
                    Academy
                  </a>
                  <a href="https://blog.actif3d.com" className="showcase-link5">
                    Blog
                  </a>
                  <Link to="/privacy-policy" className="showcase-navlink10">
                    Privacy
                  </Link>
                  <Link to="/terms-of-service" className="showcase-navlink11">
                    Terms
                  </Link>
                  <Link to="/" className="showcase-navlink12">
                    Legal
                  </Link>
                </div>
              </div>
              <div className="showcase-column2">
                <span className="showcase-header2">INFORMATION</span>
                <div className="showcase-list2">
                  <Link to="/about" className="showcase-navlink13">
                    About
                  </Link>
                  <a
                    href="https://www.linkedin.com/company/actif3d"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="showcase-link6"
                  >
                    LinkedIn
                  </a>
                  <Link to="/" className="showcase-navlink14">
                    Twitter
                  </Link>
                  <Link to="/" className="showcase-navlink15">
                    Instagram
                  </Link>
                  <a
                    href="https://www.facebook.com/actif3d"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="showcase-link7"
                  >
                    Facebook
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="showcase-bottom">
            <span className="showcase-text4">
              © 2023 Actif3D - All rights reserved
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Showcase;
