import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import GalleryCard3 from '../components/gallery-card3'
import './vlu.css'

const VLU = (props) => {
  return (
    <div className="vlu-container">
      <Helmet>
        <title>Actif3D - Your Creator Economy Assets</title>
        <meta
          name="description"
          content="A comprehensive platform for creating, trading and playing with 3D/VR/AR assets"
        />
        <meta
          property="og:title"
          content="Actif3D - Your Creator Economy Assets"
        />
        <meta
          property="og:description"
          content="A comprehensive platform for creating, trading and playing with 3D/VR/AR assets"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d75c0f3b-381e-4572-861d-fc34765a8ad7/420a2177-9e16-440e-99fc-d7241cca1be4?org_if_sml=1"
        />
      </Helmet>
      <header data-thq="thq-navbar" className="vlu-navbar">
        <Link to="/" className="vlu-navlink">
          <img alt="image" src="/logow.svg" className="vlu-branding" />
        </Link>
        <div data-thq="thq-burger-menu" className="vlu-burger-menu">
          <div className="vlu-hamburger">
            <img alt="image" src="/hamburger.svg" className="vlu-icon" />
          </div>
        </div>
        <div data-thq="thq-mobile-menu" className="vlu-mobile-menu">
          <div
            data-thq="thq-mobile-menu-nav"
            data-role="Nav"
            className="vlu-nav"
          >
            <div className="vlu-container1">
              <Link to="/" className="vlu-navlink01">
                <img alt="image" src="/logow.svg" className="vlu-image" />
              </Link>
              <div data-thq="thq-close-menu" className="vlu-menu-close">
                <svg viewBox="0 0 1024 1024" className="vlu-icon1">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav
              data-thq="thq-mobile-menu-nav-links"
              data-role="Nav"
              className="vlu-nav1"
            >
              <Link to="/about" className="vlu-navlink02">
                About
              </Link>
              <a href="https://academy.actif3d.com/" className="vlu-link">
                Academy
              </a>
              <a
                href="https://pay.actif3d.com"
                target="_blank"
                rel="noreferrer noopener"
                className="vlu-link01"
              >
                Pricing
              </a>
              <Link to="/showcase" className="vlu-link02">
                Showcase
              </Link>
              <Link to="/download" className="vlu-navlink03">
                Download
              </Link>
            </nav>
            <div className="vlu-container2">
              <a
                href="https://id.actif3d.com/signin/oauth?response_type=code&amp;client_id=actif3d&amp;redirect_uri=https://actif3d.com/auth&amp;scope=openid%20profile%20email%20name"
                className="vlu-login button"
              >
                Login
              </a>
              <a
                href="https://id.actif3d.com/signup"
                className="vlu-register button"
              >
                Register
              </a>
            </div>
          </div>
        </div>
      </header>
      <div className="vlu-gallery">
        <h2 className="vlu-heading">Đồ án môn học VR Tech</h2>
        <span className="vlu-text">Đại học Văn Lang - Lớp VR/AR VLU 2023 - Class 1 & 2</span>
        <div className="vlu-container3">
          <a href="https://a3d.app/public/gallery/vlu/2175801080181/index.html#autoplay">
            <GalleryCard3
              title="Fiction Apartment"
              subtitle="Hoàng Hữu Quốc Duy"
              image_src=" https://a3d.app/public/gallery/vlu/2175801080181/2023-07-07-20-39-32/cover.jpg"
              rootClassName="rootClassName37"
              className="vlu-component01"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080070/index.html#autoplay">
            <GalleryCard3
              title="Nhà Trên Vách Núi"
              subtitle="Hoàng Gia Bảo Khuyên"
              image_src="https://a3d.app/public/gallery/vlu/2175801080070/2023-07-07-17-51-50/cover.jpg"
              rootClassName="rootClassName38"
              className="vlu-component02"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080238/index.html#autoplay">
            <GalleryCard3
              title="Nhà Quê"
              subtitle="Liên Gia Kiệt"
              image_src=" https://a3d.app/public/gallery/vlu/2175801080238/2023-07-08-12-07-26/cover.jpg"
              rootClassName="rootClassName39"
              className="vlu-component03"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080171/index.html#autoplay">
            <GalleryCard3
                title="Far House"
                subtitle="Nguyễn Quốc Bảo"
                image_src="https://a3d.app/public/gallery/vlu/2175801080171/2023-07-07-20-32-23/cover.jpg"
                rootClassName="rootClassName36"
                className="vlu-component"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080226/index.html#autoplay">
            <GalleryCard3
              title="Nhà em Nghi"
              subtitle="Huỳnh Nguyễn Bảo Nghi"
              image_src=" https://a3d.app/public/gallery/vlu/2175801080226/2023-07-07-20-41-01/cover.jpg"
              rootClassName="rootClassName40"
              className="vlu-component04"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080059/index.html#autoplay">
            <GalleryCard3
              title="Nhà em Nguyệt"
              subtitle="Trần Hoàng Minh Nguyệt"
              image_src=" https://a3d.app/public/gallery/vlu/2175801080059/2023-07-07-17-49-04/cover.jpg"
              rootClassName="rootClassName41"
              className="vlu-component05"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080051/index.html#autoplay">
            <GalleryCard3
              title="Lovely House"
              subtitle="Trần Lê Hữu Nhân"
              image_src="https://a3d.app/public/gallery/vlu/2175801080051/2023-07-07-17-30-24/cover.jpg"
              rootClassName="rootClassName42"
              className="vlu-component06"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080078/index.html#autoplay">
            <GalleryCard3
              title="Suburban"
              subtitle="Nguyễn Minh Nhật"
              image_src="https://a3d.app/public/gallery/vlu/2175801080078/2023-07-07-17-57-34/cover.jpg"
              rootClassName="rootClassName43"
              className="vlu-component07"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080145/index.html#autoplay">
            <GalleryCard3
              title="The Myths Pagoda"
              subtitle="Hoàng Trọng Thanh Phong"
              image_src=" https://a3d.app/public/gallery/vlu/2175801080145/2023-07-07-20-26-00/cover.jpg"
              rootClassName="rootClassName44"
              className="vlu-component08"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080281/index.html#autoplay">
            <GalleryCard3
              title="Yellow Autumn Sunshine"
              subtitle="Dương Toàn Thịnh"
              image_src="https://a3d.app/public/gallery/vlu/2175801080281/2023-07-08-12-31-17/cover.jpg"
              rootClassName="rootClassName45"
              className="vlu-component09"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080040/index.html#autoplay">
            <GalleryCard3
              title="Nhà Đẹp"
              subtitle="Đoàn Thị Minh Thư"
              image_src=" https://a3d.app/public/gallery/vlu/2175801080040/2023-07-07-17-26-47/cover.jpg"
              rootClassName="rootClassName46"
              className="vlu-component10"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080041/index.html#autoplay">
            <GalleryCard3
              title="Thanh Thư"
              subtitle="Nguyễn Trần Thanh Thư"
              image_src=" https://a3d.app/public/gallery/vlu/2175801080041/2023-07-07-17-29-00/cover.jpg"
              rootClassName="rootClassName47"
              className="vlu-component11"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080053/index.html#autoplay">
            <GalleryCard3
              title="Arco Bao Tran"
              subtitle="Nguyễn Phạm Bảo Trân"
              image_src=" https://a3d.app/public/gallery/vlu/2175801080053/2023-07-07-17-31-55/cover.jpg"
              rootClassName="rootClassName48"
              className="vlu-component12"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/frank-house/index.html#autoplay">
            <GalleryCard3
              title="Frank House"
              subtitle="Nguyễn Hiền Tường Vy"
              image_src=" https://a3d.app/public/frank-house/2023-07-04-17-33-04/cover.jpg"
              rootClassName="rootClassName64"
              className="vlu-component13"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080245/index.html#autoplay">
            <GalleryCard3
              title="Nhà Khánh Vy"
              subtitle="Nguyễn Khánh Vy"
              image_src="https://a3d.app/public/gallery/vlu/2175801080245/2023-07-08-12-11-25/cover.jpg"
              rootClassName="rootClassName56"
              className="vlu-component14"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080252/index.html#autoplay">
            <GalleryCard3
              title="Deep Purple"
              subtitle="Trần Đỗ Nhật Vy"
              image_src="https://a3d.app/public/gallery/vlu/2175801080252/2023-07-08-12-20-56/cover.jpg"
              rootClassName="rootClassName65"
              className="vlu-component15"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080075/index.html#autoplay">
            <GalleryCard3
              title="Haru Kaori"
              subtitle="Dương Nguyễn Hương Xuân"
              image_src="https://a3d.app/public/gallery/vlu/2175801080075/2023-07-07-17-53-19/cover.jpg"
              rootClassName="rootClassName52"
              className="vlu-component16"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080233/index.html#autoplay">
            <GalleryCard3
              title="Up to You"
              subtitle="Phạm Ngọc Như Ý"
              image_src="https://a3d.app/public/gallery/vlu/2175801080233/2023-07-08-12-05-14/cover.jpg"
              rootClassName="rootClassName66"
              className="vlu-component17"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080251/index.html#autoplay">
            <GalleryCard3
              title="Bảo Anh"
              subtitle="Nguyễn Bảo Anh"
              image_src="https://a3d.app/public/gallery/vlu/2175801080251/2023-07-08-12-17-48/cover.jpg"
              rootClassName="rootClassName57"
              className="vlu-component18"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080243/index.html#autoplay">
            <GalleryCard3
              title="Van Anh House"
              subtitle="Nguyễn Trần Vân Anh"
              image_src="https://a3d.app/public/gallery/vlu/2175801080243/2023-07-08-12-09-50/cover.jpg"
              rootClassName="rootClassName67"
              className="vlu-component19"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080064/index.html#autoplay">
            <GalleryCard3
              title="Moonlight Sonata"
              subtitle="Nguyễn Huỳnh Trung Hiếu"
              image_src="https://a3d.app/public/gallery/vlu/2175801080064/2023-07-07-17-50-00/cover.jpg"
              rootClassName="rootClassName50"
              className="vlu-component20"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080121/index.html#autoplay">
            <GalleryCard3
              title="The Dark House"
              subtitle="Trương Bảo Hòa"
              image_src=" https://a3d.app/public/gallery/vlu/2175801080121/2023-07-07-19-49-57/cover.jpg"
              rootClassName="rootClassName68"
              className="vlu-component21"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080246/index.html#autoplay">
            <GalleryCard3
              title="Kim Huê"
              subtitle="Trương Thị Kim Huê"
              image_src=" https://a3d.app/public/gallery/vlu/2175801080246/2023-07-08-12-14-23/cover.jpg"
              rootClassName="rootClassName58"
              className="vlu-component22"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080016/index.html#autoplay">
            <GalleryCard3
              title="The Lake House"
              subtitle="Nguyễn Phạm Hoàng Minh"
              image_src="https://a3d.app/public/gallery/vlu/2175801080016/2023-07-07-17-08-55/cover.jpg"
              rootClassName="rootClassName69"
              className="vlu-component23"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080282/index.html#autoplay">
            <GalleryCard3
              title="Ringing Bell"
              subtitle="Lê Hoàng Khánh Ngân"
              image_src="https://a3d.app/public/gallery/vlu/2175801080282/2023-07-08-12-32-50/cover.jpg"
              rootClassName="rootClassName53"
              className="vlu-component24"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080166/index.html#autoplay">
            <GalleryCard3
              title="Lily House"
              subtitle="Châu Gia Ngọc"
              image_src="https://a3d.app/public/gallery/vlu/2175801080166/2023-07-07-20-27-29/cover.jpg"
              rootClassName="rootClassName70"
              className="vlu-component25"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080255/index.html#autoplay">
            <GalleryCard3
              title="Norwegian Wood"
              subtitle="Nguyễn Thị Bảo Ngọc"
              image_src=" https://a3d.app/public/gallery/vlu/2175801080255/2023-07-08-12-28-06/cover.jpg"
              rootClassName="rootClassName59"
              className="vlu-component26"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080287/index.html#autoplay">
            <GalleryCard3
              subtitle="Lê Đỗ Uyên Nhi"
              image_src="https://a3d.app/public/gallery/vlu/2175801080287/2023-07-08-12-34-24/cover.jpg"
              rootClassName="rootClassName71"
              className="vlu-component27"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080082/index.html#autoplay">
            <GalleryCard3
              title="My Smallville"
              subtitle="Đặng Uyển Sâm"
              image_src=" https://a3d.app/public/gallery/vlu/2175801080082/2023-07-07-18-04-11/cover.jpg"
              rootClassName="rootClassName49"
              className="vlu-component28"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080112/index.html#autoplay">
            <GalleryCard3
              title="The Truc's House"
              subtitle="Bùi Lê Thanh Trúc"
              image_src="https://a3d.app/public/gallery/vlu/2175801080112/2023-07-07-18-16-59/cover.jpg"
              rootClassName="rootClassName72"
              className="vlu-component29"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080259/index.html#autoplay">
            <GalleryCard3
              title="Tường Vy"
              subtitle="Đào Thị Tường Vy"
              image_src="https://a3d.app/public/gallery/vlu/2175801080259/2023-07-08-12-28-43/cover.jpg"
              rootClassName="rootClassName60"
              className="vlu-component30"
            ></GalleryCard3>
          </a>
        </div>
        {/*<Link to="/vlu" className="vlu-navlink04 button">*/}
        {/*  See more...*/}
        {/*</Link>*/}
      </div>
      <div className="vlu-gallery">
        <h2 className="vlu-heading">Đồ án môn học VR Tech</h2>
        <span className="vlu-text">Đại học Văn Lang - Lớp VR/AR VLU 2023 - Class 4 & 5</span>
        <div className="vlu-container3">
          <a href="https://a3d.app/public/gallery/vlu/2175801080062/index.html#autoplay">
            <GalleryCard3
                title="Nguyễn Nhật Quỳnh"
                subtitle=""
                image_src="https://a3d.app/public/gallery/vlu/2175801080062/2023-09-13-09-24-36/cover.jpg"
                rootClassName="rootClassName36"
                className="vlu-component"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080132/index.html#autoplay">
            <GalleryCard3
                title="Võ Hạnh Minh"
                subtitle=""
                image_src="https://a3d.app/public/gallery/vlu/2175801080132/2023-09-14-11-19-11/cover.jpg"
                rootClassName="rootClassName37"
                className="vlu-component01"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080138/index.html#autoplay">
            <GalleryCard3
                title="Lê Nhật Duy"
                subtitle=""
                image_src="https://a3d.app/public/gallery/vlu/2175801080138/2023-09-13-09-47-22/cover.jpg"
                rootClassName="rootClassName38"
                className="vlu-component02"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080147/index.html#autoplay">
            <GalleryCard3
                title="Mai Nguyên Hà"
                subtitle=""
                image_src="https://a3d.app/public/gallery/vlu/2175801080147/2023-09-13-09-14-15/cover.jpg"
                rootClassName="rootClassName39"
                className="vlu-component03"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080152/index.html#autoplay">
            <GalleryCard3
                title="Phạm Diệp Đan"
                subtitle=""
                image_src="https://a3d.app/public/gallery/vlu/2175801080152/2023-09-13-09-52-17/cover.jpg"
                rootClassName="rootClassName40"
                className="vlu-component04"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080165/index.html#autoplay">
            <GalleryCard3
                title="Nguyễn Khắt Long"
                subtitle=""
                image_src="https://a3d.app/public/gallery/vlu/2175801080165/2023-09-13-09-37-26/cover.jpg"
                rootClassName="rootClassName41"
                className="vlu-component05"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080167/index.html#autoplay">
            <GalleryCard3
                title="Dương Mai Tiến Dũng"
                subtitle=""
                image_src="https://a3d.app/public/gallery/vlu/2175801080167/2023-08-15-02-11-37/cover.jpg"
                rootClassName="rootClassName42"
                className="vlu-component06"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080168/index.html#autoplay">
            <GalleryCard3
                title="Diệp Nhật My"
                subtitle=""
                image_src="https://a3d.app/public/gallery/vlu/2175801080168/2023-09-13-09-49-24/cover.jpg"
                rootClassName="rootClassName43"
                className="vlu-component07"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080169/index.html#autoplay">
            <GalleryCard3
                title="Đào Trọng Minh Phát"
                subtitle=""
                image_src=" https://a3d.app/public/gallery/vlu/2175801080169/2023-09-13-09-35-40/cover.jpg"
                rootClassName="rootClassName44"
                className="vlu-component08"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080182/index.html#autoplay">
            <GalleryCard3
                title="Lâm Huệ Trí"
                subtitle=""
                image_src="https://a3d.app/public/gallery/vlu/2175801080182/2023-09-13-09-59-52/cover.jpg"
                rootClassName="rootClassName45"
                className="vlu-component09"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080190/index.html#autoplay">
            <GalleryCard3
                title="Phạm Mai Thảo"
                subtitle=""
                image_src=" https://a3d.app/public/gallery/vlu/2175801080190/2023-09-13-10-46-12/cover.jpg"
                rootClassName="rootClassName46"
                className="vlu-component10"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080197/index.html#autoplay">
            <GalleryCard3
                title="Nguyễn Tấn Phát"
                subtitle=""
                image_src=" https://a3d.app/public/gallery/vlu/2175801080197/2023-08-14-22-33-40/cover.jpg"
                rootClassName="rootClassName47"
                className="vlu-component11"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080202/index.html#autoplay">
            <GalleryCard3
                title="Trần Thanh Vi"
                subtitle=""
                image_src=" https://a3d.app/public/gallery/vlu/2175801080202/2023-09-13-15-20-23/cover.jpg"
                rootClassName="rootClassName48"
                className="vlu-component12"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080203/index.html#autoplay">
            <GalleryCard3
                title="Lê Gia Hân"
                subtitle=""
                image_src="https://a3d.app/public/gallery/vlu/2175801080203/2023-09-13-09-22-41/cover.jpg"
                rootClassName="rootClassName64"
                className="vlu-component13"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080217/index.html#autoplay">
            <GalleryCard3
                title="Nguyễn Huỳnh Thảo Nguyên"
                subtitle=""
                image_src="https://a3d.app/public/gallery/vlu/2175801080217/2023-09-13-14-58-16/cover.jpg"
                rootClassName="rootClassName56"
                className="vlu-component14"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080218/index.html#autoplay">
            <GalleryCard3
                title="Đinh Nhị Phi"
                subtitle=""
                image_src="https://a3d.app/public/gallery/vlu/2175801080218/2023-09-13-10-06-27/cover.jpg"
                rootClassName="rootClassName65"
                className="vlu-component15"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080257/index.html#autoplay">
            <GalleryCard3
                title="Nguyễn Thị Hồng"
                subtitle=""
                image_src="https://a3d.app/public/gallery/vlu/2175801080257/2023-09-13-09-50-58/cover.jpg"
                rootClassName="rootClassName52"
                className="vlu-component16"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080260/index.html#autoplay">
            <GalleryCard3
                title="Lê Tuấn Kiệt"
                subtitle=""
                image_src="https://a3d.app/public/gallery/vlu/2175801080260/2023-09-13-10-03-46/cover.jpg"
                rootClassName="rootClassName66"
                className="vlu-component17"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080262/index.html#autoplay">
            <GalleryCard3
                title="Nguyễn Đan Nhật Linh"
                subtitle=""
                image_src="https://a3d.app/public/gallery/vlu/2175801080262/2023-09-13-10-02-24/cover.jpg"
                rootClassName="rootClassName57"
                className="vlu-component18"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080264/index.html#autoplay">
            <GalleryCard3
                title="Nguyễn Lê Kiều Hương"
                subtitle=""
                image_src="https://a3d.app/public/gallery/vlu/2175801080264/2023-09-13-15-00-07/cover.jpg"
                rootClassName="rootClassName67"
                className="vlu-component19"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080265/index.html#autoplay">
            <GalleryCard3
                title="Đinh Thị Thuỳ Dương"
                subtitle=""
                image_src="https://a3d.app/public/gallery/vlu/2175801080265/2023-09-14-11-32-35/cover.jpg"
                rootClassName="rootClassName50"
                className="vlu-component20"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080270/index.html#autoplay">
            <GalleryCard3
                title="Mai Như Kha Viên"
                subtitle=""
                image_src="https://a3d.app/public/gallery/vlu/2175801080270/2023-09-13-09-39-09/cover.jpg"
                rootClassName="rootClassName68"
                className="vlu-component21"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080279/index.html#autoplay">
            <GalleryCard3
                title="Hồ Trọng Đức"
                subtitle=""
                image_src="https://a3d.app/public/gallery/vlu/2175801080279/2023-09-13-09-54-57/cover.jpg"
                rootClassName="rootClassName58"
                className="vlu-component22"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080286/index.html#autoplay">
            <GalleryCard3
                title="Lâm Hồng Tâm"
                subtitle=""
                image_src="https://a3d.app/public/gallery/vlu/2175801080286/2023-09-13-14-56-57/cover.jpg"
                rootClassName="rootClassName69"
                className="vlu-component23"
            ></GalleryCard3>
          </a>
        </div>
        {/*<Link to="/vlu" className="vlu-navlink04 button">*/}
        {/*  See more...*/}
        {/*</Link>*/}
      </div>

      <div className="vlu-footer">
        <div className="vlu-content">
          <div className="vlu-main">
            <div className="vlu-branding1">
              <Link to="/" className="vlu-navlink05">
                <img alt="image" src="/logow.svg" className="vlu-image1" />
              </Link>
              <span className="vlu-text1">Your Creator Economy Assets</span>
            </div>
            <div className="vlu-links">
              <div className="vlu-column">
                <span className="vlu-header">PRODUCTS &amp; SERVICES</span>
                <div className="vlu-list">
                  <Link to="/download" className="vlu-navlink06">
                    Actif3D Editor
                  </Link>
                  <Link to="/" className="vlu-navlink07">
                    Cloud Hosting
                  </Link>
                  <a
                    href="https://editor.actif3d.com"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="vlu-link34"
                  >
                    Cloud Baking
                  </a>
                  <span className="vlu-text2">
                    <span className="vlu-text3">Showcase</span>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="vlu-column1">
                <span className="vlu-header1">SUPPORTS</span>
                <div className="vlu-list1">
                  <a href="https://academy.actif3d.com" className="vlu-link35">
                    Academy
                  </a>
                  <a href="https://blog.actif3d.com" className="vlu-link36">
                    Blog
                  </a>
                  <Link to="/privacy-policy" className="vlu-navlink08">
                    Privacy
                  </Link>
                  <Link to="/terms-of-service" className="vlu-navlink09">
                    Terms
                  </Link>
                  <Link to="/" className="vlu-navlink10">
                    Legal
                  </Link>
                </div>
              </div>
              <div className="vlu-column2">
                <span className="vlu-header2">INFORMATION</span>
                <div className="vlu-list2">
                  <Link to="/about" className="vlu-navlink11">
                    About
                  </Link>
                  <a
                    href="https://www.linkedin.com/company/actif3d"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="vlu-link37"
                  >
                    LinkedIn
                  </a>
                  <Link to="/" className="vlu-navlink12">
                    Twitter
                  </Link>
                  <Link to="/" className="vlu-navlink13">
                    Instagram
                  </Link>
                  <a
                    href="https://www.facebook.com/actif3d"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="vlu-link38"
                  >
                    Facebook
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="vlu-bottom">
            <span className="vlu-text5">
              © 2023 Actif3D - All rights reserved
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VLU
