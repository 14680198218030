import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './privacy-policy.css'

const PrivacyPolicy = (props) => {
  return (
    <div className="privacy-policy-container">
      <Helmet>
        <title>Actif3D Privacy Policy</title>
        <meta name="description" content="Actif3D Privacy Policy" />
        <meta property="og:title" content="Actif3D Privacy Policy" />
        <meta property="og:description" content="Actif3D Privacy Policy" />
      </Helmet>
      <header data-thq="thq-navbar" className="privacy-policy-navbar">
        <Link to="/" className="privacy-policy-navlink">
          <img
            alt="image"
            src="/logow.svg"
            className="privacy-policy-branding"
          />
        </Link>
        <div data-thq="thq-burger-menu" className="privacy-policy-burger-menu">
          <div className="privacy-policy-hamburger">
            <img
              alt="image"
              src="/hamburger.svg"
              className="privacy-policy-icon"
            />
          </div>
        </div>
        <div data-thq="thq-mobile-menu" className="privacy-policy-mobile-menu">
          <div
            data-thq="thq-mobile-menu-nav"
            data-role="Nav"
            className="privacy-policy-nav"
          >
            <div className="privacy-policy-container1">
              <Link to="/" className="privacy-policy-navlink01">
                <img
                  alt="image"
                  src="/logow.svg"
                  className="privacy-policy-image"
                />
              </Link>
              <div
                data-thq="thq-close-menu"
                className="privacy-policy-menu-close"
              >
                <svg viewBox="0 0 1024 1024" className="privacy-policy-icon1">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav
              data-thq="thq-mobile-menu-nav-links"
              data-role="Nav"
              className="privacy-policy-nav1"
            >
              <Link to="/about" className="privacy-policy-navlink02">
                About
              </Link>
              <a
                href="https://academy.actif3d.com/"
                className="privacy-policy-link"
              >
                Academy
              </a>
              <a
                href="https://pay.actif3d.com"
                target="_blank"
                rel="noreferrer noopener"
                className="privacy-policy-link1"
              >
                Pricing
              </a>
              <Link to="/showcase" className="privacy-policy-navlink03">
                Showcase
              </Link>
              <Link to="/download" className="privacy-policy-navlink04">
                Download
              </Link>
            </nav>
            <div className="privacy-policy-container2">
              <a
                href="https://id.actif3d.com/signin/oauth?response_type=code&amp;client_id=actif3d&amp;redirect_uri=https://actif3d.com/auth&amp;scope=openid%20profile%20email%20name"
                className="privacy-policy-login button"
              >
                Login
              </a>
              <a
                href="https://id.actif3d.com/signup"
                className="privacy-policy-register button"
              >
                Register
              </a>
            </div>
          </div>
        </div>
      </header>
      <section className="privacy-policy-key-notes">
        <h2 className="privacy-policy-caption">PRIVACY POLICY</h2>
        <p className="privacy-policy-caption01">
          Effective August 31, 2021. Last Revised January 4, 2023.
          <span
            dangerouslySetInnerHTML={{
              __html: ' ',
            }}
          />
        </p>
        <p className="privacy-policy-caption02">1. Introduction</p>
        <p className="privacy-policy-caption03">
          <span>
            ACTIF3D is a Cloud-Based 3D/VR/AR Content Creation platform that can
            be used to create web VR/AR content by anyone be you an amateur or a
            professional. It is available on Windows and MacOSX. The term
            “ACTIF3D”,” we”, “our”, “us” refers to ACTIF3D Limited liability
            company, a legal entity incorporated and existing under the laws of
            The Socialist Republic of Vietnam with its registered addresses at
            No 298 Cau Giay street, Hanoi and its affiliates.
          </span>
          <br></br>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <br></br>
          <span>
            In the course of its operations, ACTIF3D needs to process some of
            your personal data (like your email address and Phone number) to
            enable you to use our Actif3D Editor, Actif3D Cloud Services and
            other services attached to it. We will principally use your personal
            data for creating a user account for you which gives you access to
            the Actif3D Platforms and services (including the Actif3D Editor
            interface Actif3D Cloud Services and our eCommerce Platform), and
            other new features that would be added in the future. We do not
            share your personal data with third parties or use it for
            third-party commercial messages.
          </span>
          <br></br>
          <br></br>
          <span>
            By using our services, you agree with this Privacy Policy.
          </span>
          <br></br>
          <br></br>
          <span>
            Actif3D VR/AR has its Head office at No 298 Cau Giay street, Hanoi.
          </span>
        </p>
        <p className="privacy-policy-caption04">2. Personal Data</p>
        <p className="privacy-policy-caption05">
          <span>
            At Actif3D VR/AR, your privacy is important to us. We are dedicated
            to keeping your data private and secure at all times.
          </span>
          <br></br>
          <br></br>
          <span>
            We are also committed to transparency, so we want you to understand
            the information we may collect from you and why we collect it. We
            aim to process as little personal data as necessary. To sign up for
            an account your email address, a password and username are needed.
            To make your next visit easier we install a cookie that remembers
            the email address you enter for yourself. If you use our service,
            ACTIF3D furthermore stores your designs with their file names.
            ACTIF3D stores this data for the term you make use of our services.
            Furthermore, ACTIF3D makes use of analytical cookies for improving
            its services (You can find more details on the cookies actually
            stored and used on this page).
          </span>
        </p>
        <p className="privacy-policy-caption06">3. Purpose</p>
        <p className="privacy-policy-caption07">
          <span>ACTIF3D processes personal data for the purpose of</span>
          <br></br>
          <span>
            - Establishing a contract with you for the use of our Actif3D Editor
            and Actif3D Cloud Services and/or websites, and other related
            services.
          </span>
          <br></br>
          <span>
            - Providing follow up on services (i.e. emails and newsletters
            Training materials and updates) and your service requests. ACTIF3D
            does not sell or otherwise distribute your personal data to third
            parties and will not use your personal data for any other purposes
            than stated above.
          </span>
        </p>
        <p className="privacy-policy-caption08">
          4. Revisions to the Privacy Policy
        </p>
        <p className="privacy-policy-caption09">
          Please note that ACTIF3D may revise this Privacy Policy from time to
          time. Each revised version shall be dated and posted on the website.
          We will inform you upfront by email and/or newsletters about any
          changes.
        </p>
        <p className="privacy-policy-caption10">5. Privacy Policy Acceptance</p>
        <p className="privacy-policy-caption11">
          If you do not agree with the Privacy Policy, you should not or no
          longer access or use the Actif3D Editor and Actif3D Cloud Services
          and/or websites or other services associated with Actif3D. By using
          and continuing to use of the Actif3D Editor and Actif3D Cloud Services
          and/or websites or other services associated with Actif3D. you accept
          the Privacy Policy and any changes made to the Privacy Policy.
        </p>
        <p className="privacy-policy-caption12">6. Questions?</p>
        <p className="privacy-policy-caption13">
          <span>
            For any question about the way ACTIF3D processes your personal data
            or the personal information ACTIF3D stores about you, please contact
            ACTIF3D by sending an email with your question to
            support@actif3d.com
          </span>
          <br className="privacy-policy-text21"></br>
          <br></br>
          <span>We speak English.</span>
        </p>
      </section>
      <div className="privacy-policy-footer">
        <div className="privacy-policy-content">
          <div className="privacy-policy-main">
            <div className="privacy-policy-branding1">
              <Link to="/" className="privacy-policy-navlink05">
                <img
                  alt="image"
                  src="/logow.svg"
                  className="privacy-policy-image1"
                />
              </Link>
              <span className="privacy-policy-text24">
                Your Creator Economy Assets
              </span>
            </div>
            <div className="privacy-policy-links">
              <div className="privacy-policy-column">
                <span className="privacy-policy-header">
                  PRODUCTS &amp; SERVICES
                </span>
                <div className="privacy-policy-list">
                  <Link to="/download" className="privacy-policy-navlink06">
                    Actif3D Editor
                  </Link>
                  <Link to="/" className="privacy-policy-navlink07">
                    Cloud Hosting
                  </Link>
                  <a
                    href="https://editor.actif3d.com"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="privacy-policy-link2"
                  >
                    Cloud Baking
                  </a>
                  <Link to="/showcase" className="privacy-policy-navlink08">
                    <span className="privacy-policy-text25">Marketplace</span>
                    <br></br>
                  </Link>
                </div>
              </div>
              <div className="privacy-policy-column1">
                <span className="privacy-policy-header1">SUPPORTS</span>
                <div className="privacy-policy-list1">
                  <a
                    href="https://academy.actif3d.com"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="privacy-policy-link3"
                  >
                    Academy
                  </a>
                  <a
                    href="https://blog.actif3d.com"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="privacy-policy-link4"
                  >
                    Blog
                  </a>
                  <Link
                    to="/privacy-policy"
                    className="privacy-policy-navlink09"
                  >
                    Privacy
                  </Link>
                  <Link
                    to="/terms-of-service"
                    className="privacy-policy-navlink10"
                  >
                    Terms
                  </Link>
                  <Link to="/" className="privacy-policy-navlink11">
                    Legal
                  </Link>
                </div>
              </div>
              <div className="privacy-policy-column2">
                <span className="privacy-policy-header2">INFORMATION</span>
                <div className="privacy-policy-list2">
                  <Link to="/about" className="privacy-policy-navlink12">
                    About
                  </Link>
                  <a
                    href="https://www.linkedin.com/company/actif3d"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="privacy-policy-link5"
                  >
                    LinkedIn
                  </a>
                  <Link to="/" className="privacy-policy-navlink13">
                    Twitter
                  </Link>
                  <Link to="/" className="privacy-policy-navlink14">
                    Instagram
                  </Link>
                  <a
                    href="https://www.facebook.com/actif3d"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="privacy-policy-link6"
                  >
                    Facebook
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="privacy-policy-bottom">
            <span className="privacy-policy-text27">
              © 2023 Actif3D - All rights reserved
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy
