import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './about.css'

const About = (props) => {
  return (
    <div className="about-container">
      <Helmet>
        <title>Actif3D Terms of Service</title>
        <meta name="description" content="Actif3D Terms of Service" />
        <meta property="og:title" content="Actif3D Terms of Service" />
        <meta property="og:description" content="Actif3D Terms of Service" />
      </Helmet>
      <header data-thq="thq-navbar" className="about-navbar">
        <Link to="/" className="about-navlink">
          <img alt="image" src="/logow.svg" className="about-branding" />
        </Link>
        <div data-thq="thq-burger-menu" className="about-burger-menu">
          <div className="about-hamburger">
            <img alt="image" src="/hamburger.svg" className="about-icon" />
          </div>
        </div>
        <div data-thq="thq-mobile-menu" className="about-mobile-menu">
          <div
            data-thq="thq-mobile-menu-nav"
            data-role="Nav"
            className="about-nav"
          >
            <div className="about-container1">
              <Link to="/" className="about-navlink01">
                <img alt="image" src="/logow.svg" className="about-image" />
              </Link>
              <div data-thq="thq-close-menu" className="about-menu-close">
                <svg viewBox="0 0 1024 1024" className="about-icon1">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav
              data-thq="thq-mobile-menu-nav-links"
              data-role="Nav"
              className="about-nav1"
            >
              <Link to="/about" className="about-navlink02">
                About
              </Link>
              <a href="https://academy.actif3d.com/" className="about-link">
                Academy
              </a>
              <a
                href="https://pay.actif3d.com"
                target="_blank"
                rel="noreferrer noopener"
                className="about-link1"
              >
                Pricing
              </a>
              <Link to="/showcase" className="about-navlink03">
                Showcase
              </Link>
              <Link to="/download" className="about-navlink04">
                Download
              </Link>
            </nav>
            <div className="about-container2">
              <a
                href="https://id.actif3d.com/signin/oauth?response_type=code&amp;client_id=actif3d&amp;redirect_uri=https://actif3d.com/auth&amp;scope=openid%20profile%20email%20name"
                className="about-login button"
              >
                Login
              </a>
              <a
                href="https://id.actif3d.com/signup"
                className="about-register button"
              >
                Register
              </a>
            </div>
          </div>
        </div>
      </header>
      <section className="about-key-notes">
        <h2 className="about-caption">
          <span>3D/VR/AR Content Creation Platform</span>
          <br></br>
        </h2>
        <p className="about-caption1">
          <span>
            ACTIF3D is a Cloud-Based 3D/VR/AR Content Creation platform that can
            be used to create web VR/AR content by anyone be you an amateur or a
            professional. It is available on Windows and MacOSX.
          </span>
          <br></br>
          <br></br>
          <span>
            ACTIF3D Limited company is a legal entity incorporated and existing
            under the laws of The Socialist Republic of Vietnam with its
            registered addresses at No 298 Cau Giay street, Hanoi and its
            affiliates.
          </span>
        </p>
      </section>
      <div className="about-footer">
        <div className="about-content">
          <div className="about-main">
            <div className="about-branding1">
              <Link to="/" className="about-navlink05">
                <img alt="image" src="/logow.svg" className="about-image1" />
              </Link>
              <span className="about-text06">Your Creator Economy Assets</span>
            </div>
            <div className="about-links">
              <div className="about-column">
                <span className="about-header">PRODUCTS &amp; SERVICES</span>
                <div className="about-list">
                  <Link to="/download" className="about-navlink06">
                    Actif3D Editor
                  </Link>
                  <Link to="/" className="about-navlink07">
                    Cloud Hosting
                  </Link>
                  <a
                    href="https://editor.actif3d.com"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="about-link2"
                  >
                    Cloud Baking
                  </a>
                  <Link to="/showcase" className="about-navlink08">
                    <span className="about-text07">Marketplace</span>
                    <br></br>
                  </Link>
                </div>
              </div>
              <div className="about-column1">
                <span className="about-header1">SUPPORTS</span>
                <div className="about-list1">
                  <a href="https://academy.actif3d.com" className="about-link3">
                    Academy
                  </a>
                  <a
                    href="https://blog.actif3d.com"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="about-link4"
                  >
                    Blog
                  </a>
                  <Link to="/privacy-policy" className="about-navlink09">
                    Privacy
                  </Link>
                  <Link to="/terms-of-service" className="about-navlink10">
                    Terms
                  </Link>
                  <Link to="/" className="about-navlink11">
                    Legal
                  </Link>
                </div>
              </div>
              <div className="about-column2">
                <span className="about-header2">INFORMATION</span>
                <div className="about-list2">
                  <Link to="/about" className="about-navlink12">
                    About
                  </Link>
                  <a
                    href="https://www.linkedin.com/company/actif3d"
                    className="about-link5"
                  >
                    LinkedIn
                  </a>
                  <Link to="/" className="about-navlink13">
                    Twitter
                  </Link>
                  <Link to="/" className="about-navlink14">
                    Instagram
                  </Link>
                  <a
                    href="https://www.facebook.com/actif3d"
                    className="about-link6"
                  >
                    Facebook
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="about-bottom">
            <span className="about-text09">
              © 2023 Actif3D - All rights reserved
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
