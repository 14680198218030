const DataItem = {
  items: [
    {
      id: "item2",
      href:"https://a3d.app/public/gallery/vlu/2175801080181/index.html#autoplay",
      image_src: "https://a3d.app/public/gallery/vlu/2175801080181/2023-07-07-20-39-32/cover.jpg",
      title: "Fiction Apartment",
      subtitle: "Hoàng Hữu Quốc Duy",
    },
    {
      id: "item3",
      href:"https://a3d.app/public/gallery/vlu/2175801080070/index.html#autoplay",
      image_src: "https://a3d.app/public/gallery/vlu/2175801080070/2023-07-07-17-51-50/cover.jpg",
      title: "Nhà Trên Vách Núi",
      subtitle: "Hoàng Gia Bảo Khuyên",
    },
    {
      id: "item4",
      href:"https://a3d.app/public/gallery/vlu/2175801080238/index.html#autoplay",
      image_src: "https://a3d.app/public/gallery/vlu/2175801080238/2023-07-08-12-07-26/cover.jpg",
      title: "Nhà Quê",
      subtitle: "Liên Gia Kiệt",
    },
    {
      id: "item1",
      href:"https://a3d.app/public/gallery/vlu/2175801080171/index.html#autoplay",
      image_src: "https://a3d.app/public/gallery/vlu/2175801080171/2023-07-07-20-32-23/cover.jpg",
      title: "Far House",
      subtitle: "Nguyễn Quốc Bảo",
    },
    {
      id: "item5",
      href:"https://a3d.app/public/gallery/vlu/2175801080226/index.html#autoplay",
      image_src: "https://a3d.app/public/gallery/vlu/2175801080226/2023-07-07-20-41-01/cover.jpg",
      title: "Nhà em Nghi",
      subtitle: "Huỳnh Nguyễn Bảo Nghi",
    },
    {
      id: "item6",
      href:"https://a3d.app/public/gallery/vlu/2175801080059/index.html#autoplay",
      image_src: "https://a3d.app/public/gallery/vlu/2175801080059/2023-07-07-17-49-04/cover.jpg",
      title: "Nhà em Nguyệt",
      subtitle: "Trần Hoàng Minh Nguyệt",
    },
    {
      id: "item7",
      href:"https://a3d.app/public/gallery/vlu/2175801080051/index.html#autoplay",
      image_src: "https://a3d.app/public/gallery/vlu/2175801080051/2023-07-07-17-30-24/cover.jpg",
      title: "Lovely House",
      subtitle: "Trần Lê Hữu Nhân",
    },
    {
      id: "item8",
      href:"https://a3d.app/public/gallery/vlu/2175801080078/index.html#autoplay",
      image_src: "https://a3d.app/public/gallery/vlu/2175801080078/2023-07-07-17-57-34/cover.jpg",
      title: "Suburban",
      subtitle: "Nguyễn Minh Nhật",
    },
    {
      id: "item9",
      href:"https://a3d.app/public/gallery/vlu/2175801080145/index.html#autoplay",
      image_src: "https://a3d.app/public/gallery/vlu/2175801080145/2023-07-07-20-26-00/cover.jpg",
      title: "The Myths Pagoda",
      subtitle: "Hoàng Trọng Thanh Phong",
    },
    {
      id: "item10",
      href:"https://a3d.app/public/gallery/vlu/2175801080281/index.html#autoplay",
      image_src: "https://a3d.app/public/gallery/vlu/2175801080281/2023-07-08-12-31-17/cover.jpg",
      title: "Yellow Autumn Sunshine",
      subtitle: "Dương Toàn Thịnh",
    },
    {
      id: "item11",
      href:"https://a3d.app/public/gallery/vlu/2175801080040/index.html#autoplay",
      image_src: "https://a3d.app/public/gallery/vlu/2175801080040/2023-07-07-17-26-47/cover.jpg",
      title: "Nhà Đẹp",
      subtitle: "Đoàn Thị Minh Thư",
    },
    {
      id: "item12",
      href:"https://a3d.app/public/gallery/vlu/2175801080041/index.html#autoplay",
      image_src: "https://a3d.app/public/gallery/vlu/2175801080041/2023-07-07-17-29-00/cover.jpg",
      title: "Thanh Thư",
      subtitle: "Nguyễn Trần Thanh Thư",
    },
    {
      id: "item13",
      href:"https://a3d.app/public/gallery/vlu/2175801080053/index.html#autoplay",
      image_src: "https://a3d.app/public/gallery/vlu/2175801080053/2023-07-07-17-31-55/cover.jpg",
      title: "Arco Bao Tran",
      subtitle: "Nguyễn Phạm Bảo Trân",
    },
    {
      id: "item14",
      href:"https://a3d.app/public/frank-house/index.html#autoplay",
      image_src: "https://a3d.app/public/frank-house/2023-07-04-17-33-04/cover.jpg",
      title: "Frank House",
      subtitle: "Nguyễn Hiền Tường Vy",
    },
    {
      id: "item15",
      href:"https://a3d.app/public/gallery/vlu/2175801080245/index.html#autoplay",
      image_src: "https://a3d.app/public/gallery/vlu/2175801080245/2023-07-08-12-11-25/cover.jpg",
      title: "Nhà Khánh Vy",
      subtitle: "Nguyễn Khánh Vy",
    },
    {
      id: "item16",
      href:"https://a3d.app/public/gallery/vlu/2175801080252/index.html#autoplay",
      image_src: "https://a3d.app/public/gallery/vlu/2175801080252/2023-07-08-12-20-56/cover.jpg",
      title: "Deep Purple",
      subtitle: "Trần Đỗ Nhật Vy",
    },
    {
      id: "item17",
      href:"https://a3d.app/public/gallery/vlu/2175801080075/index.html#autoplay",
      image_src: "https://a3d.app/public/gallery/vlu/2175801080075/2023-07-07-17-53-19/cover.jpg",
      title: "Haru Kaori",
      subtitle: "Dương Nguyễn Hương Xuân",
    },
    {
      id: "item18",
      href:"https://a3d.app/public/gallery/vlu/2175801080233/index.html#autoplay",
      image_src: "https://a3d.app/public/gallery/vlu/2175801080233/2023-07-08-12-05-14/cover.jpg",
      title: "Up to You",
      subtitle: "Phạm Ngọc Như Ý",
    },
    {
      id: "item19",
      href:"https://a3d.app/public/gallery/vlu/2175801080251/index.html#autoplay",
      image_src: "https://a3d.app/public/gallery/vlu/2175801080251/2023-07-08-12-17-48/cover.jpg",
      title: "Bảo Anh",
      subtitle: "Nguyễn Bảo Anh",
    },
    {
      id: "item20",
      href:"https://a3d.app/public/gallery/vlu/2175801080243/index.html#autoplay",
      image_src: "https://a3d.app/public/gallery/vlu/2175801080243/2023-07-08-12-09-50/cover.jpg",
      title: "Van Anh House",
      subtitle: "Nguyễn Trần Vân Anh",
    },
    {
      id: "item21",
      href:"https://a3d.app/public/gallery/vlu/2175801080064/index.html#autoplay",
      image_src: "https://a3d.app/public/gallery/vlu/2175801080064/2023-07-07-17-50-00/cover.jpg",
      title: "Moonlight Sonata",
      subtitle: "Nguyễn Huỳnh Trung Hiếu",
    },
    {
      id: "item22",
      href:"https://a3d.app/public/gallery/vlu/2175801080121/index.html#autoplay",
      image_src: "https://a3d.app/public/gallery/vlu/2175801080121/2023-07-07-19-49-57/cover.jpg",
      title: "The Dark House",
      subtitle: "Trương Bảo Hòa",
    },
    {
      id: "item23",
      href:"https://a3d.app/public/gallery/vlu/2175801080246/index.html#autoplay",
      image_src: "https://a3d.app/public/gallery/vlu/2175801080246/2023-07-08-12-14-23/cover.jpg",
      title: "Kim Huê",
      subtitle: "Trương Thị Kim Huê",
    },
    {
      id: "item24",
      href:"https://a3d.app/public/gallery/vlu/2175801080016/index.html#autoplay",
      image_src: "https://a3d.app/public/gallery/vlu/2175801080016/2023-07-07-17-08-55/cover.jpg",
      title: "The Lake House",
      subtitle: "Nguyễn Phạm Hoàng Minh",
    },
    {
      id: "item25",
      href:"https://a3d.app/public/gallery/vlu/2175801080282/index.html#autoplay",
      image_src: "https://a3d.app/public/gallery/vlu/2175801080282/2023-07-08-12-32-50/cover.jpg",
      title: "Ringing Bell",
      subtitle: "Lê Hoàng Khánh Ngân",
    },
    {
      id: "item26",
      href:"https://a3d.app/public/gallery/vlu/2175801080166/index.html#autoplay",
      image_src: "https://a3d.app/public/gallery/vlu/2175801080166/2023-07-07-20-27-29/cover.jpg",
      title: "Lily House",
      subtitle: "Châu Gia Ngọc",
    },
    {
      id: "item27",
      href:"https://a3d.app/public/gallery/vlu/2175801080255/index.html#autoplay",
      image_src: "https://a3d.app/public/gallery/vlu/2175801080255/2023-07-08-12-28-06/cover.jpg",
      title: "Norwegian Wood",
      subtitle: "Nguyễn Thị Bảo Ngọc",
    },
    {
      id: "item28",
      href:"https://a3d.app/public/gallery/vlu/2175801080287/index.html#autoplay",
      image_src: "https://a3d.app/public/gallery/vlu/2175801080287/2023-07-08-12-34-24/cover.jpg",
      title: "Lê Đỗ Uyên Nhi",
      subtitle: "Lê Đỗ Uyên Nhi",
    },
    {
      id: "item29",
      href:"https://a3d.app/public/gallery/vlu/2175801080082/index.html#autoplay",
      image_src: "https://a3d.app/public/gallery/vlu/2175801080082/2023-07-07-18-04-11/cover.jpg",
      title: "My Smallville",
      subtitle: "Đặng Uyển Sâm",
    },
    {
      id: "item30",
      href:"https://a3d.app/public/gallery/vlu/2175801080112/index.html#autoplay",
      image_src: "https://a3d.app/public/gallery/vlu/2175801080112/2023-07-07-18-16-59/cover.jpg",
      title: "The Truc's House",
      subtitle: "Bùi Lê Thanh Trúc",
    },
    {
      id: "item31",
      href:"https://a3d.app/public/gallery/vlu/2175801080259/index.html#autoplay",
      image_src: "https://a3d.app/public/gallery/vlu/2175801080259/2023-07-08-12-28-43/cover.jpg",
      title: "Tường Vy",
      subtitle: "Đào Thị Tường Vy",
    },
  ],
};

export default DataItem;
