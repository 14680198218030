import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './download.css'

const Download = (props) => {
  return (
    <div className="download-container">
      <Helmet>
        <title>Actif3D - Your Creator Economy Assets</title>
        <meta
          name="description"
          content="A comprehensive platform for creating, trading and playing with 3D/VR/AR assets"
        />
        <meta
          property="og:title"
          content="Actif3D - Your Creator Economy Assets"
        />
        <meta
          property="og:description"
          content="A comprehensive platform for creating, trading and playing with 3D/VR/AR assets"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d75c0f3b-381e-4572-861d-fc34765a8ad7/6b3bda8b-50c6-4759-bb38-e263bae60ea7?org_if_sml=1"
        />
      </Helmet>
      <header data-thq="thq-navbar" className="download-navbar">
        <Link to="/" className="download-navlink">
          <img alt="image" src="/logow.svg" className="download-branding" />
        </Link>
        <div data-thq="thq-burger-menu" className="download-burger-menu">
          <div className="download-hamburger">
            <img alt="image" src="/hamburger.svg" className="download-icon" />
          </div>
        </div>
        <div data-thq="thq-mobile-menu" className="download-mobile-menu">
          <div
            data-thq="thq-mobile-menu-nav"
            data-role="Nav"
            className="download-nav"
          >
            <div className="download-container1">
              <Link to="/" className="download-navlink01">
                <img alt="image" src="/logow.svg" className="download-image" />
              </Link>
              <div data-thq="thq-close-menu" className="download-menu-close">
                <svg viewBox="0 0 1024 1024" className="download-icon1">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav
              data-thq="thq-mobile-menu-nav-links"
              data-role="Nav"
              className="download-nav1"
            >
              <Link to="/about" className="download-navlink02">
                About
              </Link>
              <a href="https://academy.actif3d.com/" className="download-link">
                Academy
              </a>
              <a
                href="https://pay.actif3d.com"
                target="_blank"
                rel="noreferrer noopener"
                className="download-link1"
              >
                Pricing
              </a>
              <Link to="/showcase" className="download-navlink03">
                Showcase
              </Link>
              <Link to="/download" className="download-navlink04">
                Download
              </Link>
            </nav>
            <div className="download-container2">
              <a
                href="https://id.actif3d.com/signin/oauth?response_type=code&amp;client_id=actif3d&amp;redirect_uri=https://actif3d.com/auth&amp;scope=openid%20profile%20email%20name"
                className="download-login button"
              >
                Login
              </a>
              <a
                href="https://id.actif3d.com/signup"
                className="download-register button"
              >
                Register
              </a>
            </div>
          </div>
        </div>
      </header>
      <section className="download-get-started">
        <div className="download-header-container">
          <div className="download-header">
            <h2 className="download-heading">Actif3D Editor</h2>
          </div>
          <p className="download-caption">Windows 10/ Windows 11</p>
          <div className="download-button">
            <a
              href="http://h3d.me/a3dwin64"
              target="_blank"
              rel="noreferrer noopener"
              className="download-button1 button"
            >
              <span>Download Actif3D</span>
            </a>
          </div>
        </div>
        <img
          alt="image"
          src="/getstarted-800w.png"
          className="download-image1"
        />
      </section>
      <div className="download-footer">
        <div className="download-content">
          <div className="download-main">
            <div className="download-branding1">
              <Link to="/" className="download-navlink05">
                <img alt="image" src="/logow.svg" className="download-image2" />
              </Link>
              <span className="download-text1">
                Your Creator Economy Assets
              </span>
            </div>
            <div className="download-links">
              <div className="download-column">
                <span className="download-header1">
                  PRODUCTS &amp; SERVICES
                </span>
                <div className="download-list">
                  <Link to="/download" className="download-navlink06">
                    Actif3D Editor
                  </Link>
                  <Link to="/" className="download-navlink07">
                    Cloud Hosting
                  </Link>
                  <a
                    href="https://editor.actif3d.com"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="download-link2"
                  >
                    Cloud Baking
                  </a>
                  <Link to="/showcase" className="download-navlink08">
                    <span className="download-text2">Showcase</span>
                    <br></br>
                  </Link>
                </div>
              </div>
              <div className="download-column1">
                <span className="download-header2">SUPPORTS</span>
                <div className="download-list1">
                  <a
                    href="https://academy.actif3d.com"
                    className="download-link3"
                  >
                    Academy
                  </a>
                  <a href="https://blog.actif3d.com" className="download-link4">
                    Blog
                  </a>
                  <Link to="/privacy-policy" className="download-navlink09">
                    Privacy
                  </Link>
                  <Link to="/terms-of-service" className="download-navlink10">
                    Terms
                  </Link>
                  <Link to="/" className="download-navlink11">
                    Legal
                  </Link>
                </div>
              </div>
              <div className="download-column2">
                <span className="download-header3">INFORMATION</span>
                <div className="download-list2">
                  <Link to="/about" className="download-navlink12">
                    About
                  </Link>
                  <a
                    href="https://www.linkedin.com/company/actif3d"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="download-link5"
                  >
                    LinkedIn
                  </a>
                  <Link to="/" className="download-navlink13">
                    Twitter
                  </Link>
                  <Link to="/" className="download-navlink14">
                    Instagram
                  </Link>
                  <a
                    href="https://www.facebook.com/actif3d"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="download-link6"
                  >
                    Facebook
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="download-bottom">
            <span className="download-text4">
              © 2023 Actif3D - All rights reserved
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Download
