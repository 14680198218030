import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";

import "./style.css";
import Auth from "./views/auth";
import Showcase from "./views/showcase";
import TermsOfService from "./views/terms-of-service";
import Home from "./views/home";
import About from "./views/about";
import Download from "./views/download";
import PrivacyPolicy from "./views/privacy-policy";
import VLU from "./views/vlu";

const App = () => {
  return (
    <Router>
      <div>
        <Route component={Download} exact path="/auth" />
        <Route component={Showcase} exact path="/showcase" />
        <Route component={TermsOfService} exact path="/terms-of-service" />
        <Route component={Home} exact path="/" />
        <Route component={VLU} exact path="/vlu" />
        <Route component={About} exact path="/about" />
        <Route component={Download} exact path="/download" />
        <Route component={PrivacyPolicy} exact path="/privacy-policy" />
      </div>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById("app"));
