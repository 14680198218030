import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './terms-of-service.css'

const TermsOfService = (props) => {
  return (
    <div className="terms-of-service-container">
      <Helmet>
        <title>Actif3D Terms of Service</title>
        <meta name="description" content="Actif3D Terms of Service" />
        <meta property="og:title" content="Actif3D Terms of Service" />
        <meta property="og:description" content="Actif3D Terms of Service" />
      </Helmet>
      <header data-thq="thq-navbar" className="terms-of-service-navbar">
        <Link to="/" className="terms-of-service-navlink">
          <img
            alt="image"
            src="/logow.svg"
            className="terms-of-service-branding"
          />
        </Link>
        <div
          data-thq="thq-burger-menu"
          className="terms-of-service-burger-menu"
        >
          <div className="terms-of-service-hamburger">
            <img
              alt="image"
              src="/hamburger.svg"
              className="terms-of-service-icon"
            />
          </div>
        </div>
        <div
          data-thq="thq-mobile-menu"
          className="terms-of-service-mobile-menu"
        >
          <div
            data-thq="thq-mobile-menu-nav"
            data-role="Nav"
            className="terms-of-service-nav"
          >
            <div className="terms-of-service-container1">
              <Link to="/" className="terms-of-service-navlink01">
                <img
                  alt="image"
                  src="/logow.svg"
                  className="terms-of-service-image"
                />
              </Link>
              <div
                data-thq="thq-close-menu"
                className="terms-of-service-menu-close"
              >
                <svg viewBox="0 0 1024 1024" className="terms-of-service-icon1">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav
              data-thq="thq-mobile-menu-nav-links"
              data-role="Nav"
              className="terms-of-service-nav1"
            >
              <Link to="/about" className="terms-of-service-navlink02">
                About
              </Link>
              <a
                href="https://academy.actif3d.com/"
                className="terms-of-service-link"
              >
                Academy
              </a>
              <a
                href="https://pay.actif3d.com"
                target="_blank"
                rel="noreferrer noopener"
                className="terms-of-service-link1"
              >
                Pricing
              </a>
              <Link to="/showcase" className="terms-of-service-navlink03">
                Showcase
              </Link>
              <Link to="/download" className="terms-of-service-navlink04">
                Download
              </Link>
            </nav>
            <div className="terms-of-service-container2">
              <a
                href="https://id.actif3d.com/signin/oauth?response_type=code&amp;client_id=actif3d&amp;redirect_uri=https://actif3d.com/auth&amp;scope=openid%20profile%20email%20name"
                className="terms-of-service-login button"
              >
                Login
              </a>
              <a
                href="https://id.actif3d.com/signup"
                className="terms-of-service-register button"
              >
                Register
              </a>
            </div>
          </div>
        </div>
      </header>
      <section className="terms-of-service-key-notes">
        <h2 className="terms-of-service-caption">TERMS OF SERVICE</h2>
        <p className="terms-of-service-caption01">
          Effective August 31, 2021. Last Revised Jun 5, 2023.
          <span
            dangerouslySetInnerHTML={{
              __html: ' ',
            }}
          />
        </p>
        <p className="terms-of-service-caption02">
          These Terms of Service (“Terms”) govern your use of the Actif3D
          platform and all services connected to the Actif3D platform,
          including, but not limited to the Actif3D Platform and all sub
          Applications, Actif3D Cloud and Rendering services, Actif3D eCommerce
          platform and Actif3D websites and all related mobile applications.
          (“Services”), provided by the Actif3D Team,
        </p>
        <p className="terms-of-service-caption03">ACCEPTANCE OF TERMS</p>
        <p className="terms-of-service-caption04">
          <span>
            This Terms of Service document is an agreement you must accept in
            order to use Actif3D. net’s Services. This is a legal agreement
            (“Agreement”) between You and Actif3D (“Company”), for use of the
            Actif3D services, Actif3D, (“Services”) which You selected or
            initiated. “You” refers to the individual who registered and/or
            provided in the Actif3D website his or her credit card or other
            payment mechanism for the Services or, if the Services are being
            purchased on behalf of an entity by an individual authorized to
            purchase the Services on behalf of such entity, then “You” refers to
            such entity.
          </span>
          <br className="terms-of-service-text001"></br>
          <br></br>
          <span>
            If you are entering into this TOS agreement on behalf of a company
            or other legal entity, you represent that you have the authority to
            bind such entity to this TOS. In that case, the terms “you” or
            “your” shall also refer to such entity, its staff and its
            affiliates, as applicable. If you do not have such authority, or if
            you do not agree with this TOS, you may not use the Service. You
            acknowledge that this TOS is a contract between you and Actif3D,
            even though it is electronic and is not physically signed by you and
            Actif3D, but it governs your use of the Service.
          </span>
          <br className="terms-of-service-text004"></br>
          <br></br>
          <span>
            Violation of any of the terms of service may result in the
            termination of your account.
          </span>
        </p>
        <p className="terms-of-service-caption05">SERVICES</p>
        <p className="terms-of-service-caption06">
          <span>
            The Company may at its sole discretion modify the features of the
            Services from time to time without prior notice. Actif3D reserves
            the right at any time and from time to time to modify or
            discontinue, temporarily or permanently, the Service (or any part
            thereof) with or without notice. You agree that Actif3D shall not be
            liable to you or to any third party for any modification, suspension
            or discontinuance of the Service.
          </span>
          <br className="terms-of-service-text008"></br>
          <br></br>
          <span>
            If any users send us any feedback or suggestions regarding the
            Service, you grant Actif3D an unlimited, irrevocable, perpetual,
            free license to use any such feedback or suggestions for any purpose
            without any obligation to you.
          </span>
        </p>
        <p className="terms-of-service-caption07">ACCESS TO THE SERVICES</p>
        <p className="terms-of-service-caption08">
          <span>
            You will receive a password and account designation upon completing
            the Service’s registration process. You are responsible for
            maintaining the confidentiality of the password and account and are
            fully responsible for all activities that occur under your password
            or account. You agree to (a) immediately notify Actif3D of any
            unauthorized use of your password or account or any other breach of
            security; and (b) ensure that you exit from your account at the end
            of each session.
          </span>
          <br className="terms-of-service-text012"></br>
          <br className="terms-of-service-text013"></br>
          <span>
            You may not use the service for any purpose which is illegal or
            violates any laws in your jurisdiction, in the jurisdiction of The
            Socialist Republic of Vietnam or in any way that intentionally or
            unintentionally violates any applicable local, state, national or
            international law.
          </span>
          <br className="terms-of-service-text015"></br>
          <br className="terms-of-service-text016"></br>
          <span>
            Any software associated with the Services is protected by copyright
            laws and international copyright treaties, as well as other
            intellectual property laws and treaties.
          </span>
        </p>
        <p className="terms-of-service-caption09">YOUR CONTENT AND DATA</p>
        <p className="terms-of-service-caption10">
          <span>
            We claim no intellectual property rights over the data and material
            you provide to the Service. All materials uploaded remain yours.
          </span>
          <br className="terms-of-service-text019"></br>
          <br className="terms-of-service-text020"></br>
          <span>
            “Data” means any data and content you upload, post, transmit or
            otherwise make available via the Services including messages you
            send, files you upload, comments you make on files, profile
            information and anything else you enter or upload into the Service.
            Actif3D will make commercially reasonable efforts to ensure that all
            facilities used to store and process Your Data meet a high standard
            for security.
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <br className="terms-of-service-text022"></br>
          <br className="terms-of-service-text023"></br>
          <span>
            In order for us to provide services to you, we require that you
            grant us certain rights with respect to your Data. For example, we
            need to be able to transmit, store and copy your Data in order to
            display it to you and your colleagues, to index it so you are able
            to search it, to make backups to prevent data loss, and so on. Your
            acceptance of this TOS gives us the permission to do so and grants
            us any such rights necessary to provide the service to you, only for
            the purpose of providing the service (and for no other purpose).
            This permission includes allowing us to use third-party service
            providers (for example Amazon Web Services) in the operation and
            administration of the Service and the rights granted to us are
            extended to these third parties to the degree necessary in order for
            the Service to be provided. Depending on the service, this may
            involve moving your data across jurisdictional lines, or across
            country borders.
          </span>
          <br className="terms-of-service-text025"></br>
          <br className="terms-of-service-text026"></br>
          <span>
            The Company will not share, disclose, sell, lease, modify, delete or
            distribute any Data provided by you in any manner. The Company will
            also not view the Data provided by you except when given permission
            by you for the purpose of support.
          </span>
          <br className="terms-of-service-text028"></br>
          <br className="terms-of-service-text029"></br>
          <span>
            You agree that Actif3D may include your business name in a list of
            Actif3D’s customers online and in print and electronic marketing
            materials in an appropriate fashion. If you’d prefer to not have
            your details included please email us at marketing@Actif3D.com
          </span>
          <br className="terms-of-service-text031"></br>
          <br className="terms-of-service-text032"></br>
          <span>CONDUCT ON Actif3D Platform</span>
          <br className="terms-of-service-text034"></br>
          <br className="terms-of-service-text035"></br>
          <span>You may not use the Actif3D Platform to:</span>
          <br className="terms-of-service-text037"></br>
          <br className="terms-of-service-text038"></br>
          <span>
            Upload, post, transmit, or otherwise make available any of Your Data
            that is unlawful, harmful, threatening, abusive, harassing,
            tortious, defamatory, vulgar, obscene, libellous, invasive of
            another’s privacy, hateful, or racially, ethnically, or otherwise
            objectionable
          </span>
          <br className="terms-of-service-text040"></br>
          <br className="terms-of-service-text041"></br>
          <span>
            Upload or transmit images that contain nudity, weapons, violence or
            drugs
          </span>
          <br className="terms-of-service-text043"></br>
          <br className="terms-of-service-text044"></br>
          <span>
            Impersonate, or misrepresent your relationship with, any person or
            entity
          </span>
          <br className="terms-of-service-text046"></br>
          <br className="terms-of-service-text047"></br>
          <span>
            Upload or transmit any Content that you do not have a right to make
            available, or that infringes any patent, trademark, trade secret,
            copyright, privacy, or other proprietary rights of any party;
          </span>
          <br className="terms-of-service-text049"></br>
          <br className="terms-of-service-text050"></br>
          <span>
            You are solely liable and responsible for your interactions with
            other users. We reserve the right to monitor disputes between you
            and other users, but we have no obligation to do so.
          </span>
          <br></br>
          <br></br>
          <span>
            We reserve the right to terminate any account that is in breach of
            these terms.
          </span>
          <br></br>
        </p>
        <p className="terms-of-service-caption11">BILLING</p>
        <p className="terms-of-service-caption12">
          <span>
            You are not obligated to purchase any of the Services. if You elect
            to purchase Service packages or additional Services, you may elect
            to provide a credit card or other payment mechanism selected by You.
          </span>
          <br className="terms-of-service-text057"></br>
          <br></br>
          <span>
            You agree that the Company may charge to Your credit card or other
            payment mechanism selected by You and approved by the Company for
            Your Prepaid Account (“Your Account”) and all amounts due and owing
            for the Services, including service fees, subscription fees or any
            other fee or charge associated with Your use of the Services. if
            there are any annual, monthly or similar periodic fees for Your
            subscription, these fees will be billed automatically to the credit
            card designated during the registration process for the Services, or
            subsequently designated to the Company at the start of the
            subscription period and at the start of each renewal period, unless
            You terminate Your subscription before the relevant period begins.
          </span>
          <br className="terms-of-service-text060"></br>
          <br></br>
          <span>
            Prices of all Services are subject to change at any time. The
            company will strive wherever possible to give advance notice. Such
            notice may be provided at any time by posting the changes to the
            Actif3D website or the Service itself.
          </span>
          <br className="terms-of-service-text063"></br>
          <br></br>
          <span>
            All payments authorized by you into our account are final. There is
            no refunding of your payments regardless of whether you use the
            Services or not.
          </span>
          <br className="terms-of-service-text066"></br>
          <br></br>
          <span>
            In connection with your purchase and/or use of the Service you may
            be subject to taxes, including, without limitation, sales and use
            taxes, by any authority which has jurisdiction to impose such taxes.
            You agree that the obligation and payment of any such taxes shall be
            your sole and absolute responsibility, and you agree to indemnify
            Actif3D to the extent that Actif3D incurs any obligations or other
            liabilities in connection with such taxes.
          </span>
          <br className="terms-of-service-text069"></br>
          <br></br>
          <span>
            You agree that in the event The Company is unable to collect the
            fees owed to the Company for the Services through your Account, the
            Company may take any other steps it deems necessary to collect such
            fees from you and that you will be responsible for all costs and
            expenses incurred by the Company in connection with such collection
            activity, including collection fees and legal costs. You further
            agree that the Company may collect interest at the lesser of 1.5%
            per month or the highest amount permitted by law on any amounts not
            paid when due.
          </span>
        </p>
        <p className="terms-of-service-caption13">
          CHANGING YOUR SERVICE LEVEL
        </p>
        <p className="terms-of-service-caption14">
          <span className="terms-of-service-text072">
            Some services provided by Actif3D allow you to upgrade or downgrade
            your service levels.
          </span>
          <br className="terms-of-service-text073"></br>
          <br className="terms-of-service-text074"></br>
          <span className="terms-of-service-text075">
            If you upgrade your service level from one package to a higher
            priced package, the Company will charge you thus: 1) if you pay
            monthly, your first payment will be the difference between the
            higher priced package and your current package price, whereby monies
            received by Company for your current package price is pro-rated
            based on the actual number of days until your scheduled monthly
            payment date. Your regularly scheduled monthly payment date will
            remain the same as your initial purchase date, with subsequent
            monthly charges at the higher package price.
          </span>
          <br className="terms-of-service-text076"></br>
          <br className="terms-of-service-text077"></br>
          <span className="terms-of-service-text078">
            If you purchase or upgrade additional services that are charged on a
            monthly basis, your first payment for those additional services will
            be the price of the additional services pro-rated based on the
            actual number of days until your next monthly payment date. Your
            scheduled monthly payment date will remain the same as your initial
            purchase date, with the full price of the additional services
            reflected in subsequent monthly charges. If you purchase additional
            services that are charged on an annual basis, your first payment for
            those additional services will be the full price of the additional
            service. The renewal date for such annual additional services will
            be the date of upgrade the subsequent year.
          </span>
          <br className="terms-of-service-text079"></br>
          <br className="terms-of-service-text080"></br>
          <span className="terms-of-service-text081">
            There are limited package downgrade options available and no
            refunds. Subscribers that wish to move from a higher priced package
            to a lower-priced package must let the current service level expire
            then repurchase at the desired service level (or use the Free
            version if available).
          </span>
          <br className="terms-of-service-text082"></br>
          <br className="terms-of-service-text083"></br>
          <span className="terms-of-service-text084">
            Subscribers that wish to remove additional services from their
            account must let the current additional services expire first then
            repurchase any desired additional services. No refunds available
          </span>
        </p>
        <p className="terms-of-service-caption15">CANCELLATION OF ACCOUNT</p>
        <p className="terms-of-service-caption16">
          <span>
            You are solely responsible for properly cancelling your account. An
            email or phone request to cancel your account is not considered
            cancellation. You can cancel your account at any time by accessing
            the subscription settings panel in your settings page.
          </span>
          <br className="terms-of-service-text086"></br>
          <br className="terms-of-service-text087"></br>
          <span>
            All of your content will immediately be inaccessible from the
            Service upon cancellation. Within 30 days, all this content will be
            permanently deleted from all backups and logs. This information
            cannot be recovered once it has been permanently deleted.
          </span>
          <br className="terms-of-service-text089"></br>
          <br className="terms-of-service-text090"></br>
          <span>
            The Company, in its sole discretion, has the right to suspend or
            terminate your account and refuse any and all current or future use
            of the Service for any reason at any time. Such termination of the
            Service will result in the deactivation or deletion of your Account
            or your access to your Account, and the forfeiture and
            relinquishment of all content in your account. The Company reserves
            the right to refuse service to anyone for any reason at any time.
          </span>
        </p>
        <p className="terms-of-service-caption17">ABUSE OF FEATURES</p>
        <p className="terms-of-service-caption18">
          <span>
            The features made available through the Actif3D services are
            intended for the normal use of the software. Abuse of features may
            result in a ban or removal of service. (E.g: Do not use
            reply-by-email address in automated systems such as Google Alerts
            etc. Do not auto-direct emails to a specific reply-by-email Actif3D
            email address) RSS feeds used where Actif3D data is published
            publicly may be disabled. RSS feeds are intended for private
            consumption only.
          </span>
          <br className="terms-of-service-text093"></br>
          <br></br>
          <span>
            In extreme cases, we reserve the right to temporarily suspend your
            account if your usage significantly exceeds the average usage of
            other Service customers and/or there’s a danger that your usage of
            the services is causing disruption to other users. We’ll always
            attempt to reach out to the account owner before taking any action
            except in rare cases where the level of use may negatively,
            immediately impact the performance of the Service for other
            customers.
          </span>
        </p>
        <p className="terms-of-service-caption19">
          NO RESELLING OR USE OUTSIDE OF PERMITTED TERMS
        </p>
        <p className="terms-of-service-caption20">
          Other than using the Services as permitted under the terms and
          conditions of this Agreement or other written agreements between you
          and the Company, “You” may not resell, distribute, make any commercial
          use of, or use on a time-share or service bureau basis.
        </p>
        <p className="terms-of-service-caption21">
          USE OF THIRD PARTY APPLICATIONS AND API ACCESS
        </p>
        <p className="terms-of-service-caption22">
          <span className="terms-of-service-text096">
            If you elect to utilize any third-party application in connection
            with your use of the Service, by doing so you are consenting to your
            Content being shared with such third-party application. To
            understand how such third party application provider utilizes your
            Content and other information, you should review their privacy
            policy. You expressly understand and agree that the Company shall
            not be liable for any damages or losses resulting from your use of
            the API or third-party products that access data via the API.
          </span>
          <br className="terms-of-service-text097"></br>
          <br className="terms-of-service-text098"></br>
          <span className="terms-of-service-text099">
            Abuse or excessively frequent requests to the Service via the API
            may result in the temporary or permanent suspension of your
            account’s access to the API. The Company, in its sole discretion,
            will determine abuse or excessive usage of the API. The Company will
            make a reasonable attempt via email to warn the account owner prior
            to suspension.
          </span>
        </p>
        <p className="terms-of-service-caption23">PROPRIETARY RIGHTS</p>
        <p className="terms-of-service-caption24">
          The Company and/or its suppliers, as applicable, retain ownership of
          all proprietary rights in the Services and in all trade names,
          trademarks and servicemarks associated or displayed with the Services.
          You will not remove, deface or obscure any of the Company’s or its
          suppliers’ copyright or trademark notices and/or legends or other
          proprietary notices on, incorporated therein or associated with the
          Services. You may not reverse engineer, reverse compile or otherwise
          reduce to human readable form any software associated with the
          Services.
        </p>
        <p className="terms-of-service-caption25">
          DISCLAIMER; limitation of liability
        </p>
        <p className="terms-of-service-caption26">
          <span className="terms-of-service-text100">
            Disclaimer. Neither any opinion, advice or statement of us or our
            affiliates, licensors, suppliers, agents or visitors, whether made
            on our website or otherwise nor the results of any operation of the
            Service by you constitute representations or warranties of us or
            give rise to any rights of reliance or otherwise for the benefit of
            you or any third party. You are responsible for implementing
            sufficient procedures and checkpoints to satisfy your particular
            requirements for accuracy of data input and output, and for
            maintaining a means external to this website for the reconstruction
            of any lost data. We do not assume any responsibility or risk for
            your use of our Service and the Internet. ANY AND ALL SERVICES
            PROVIDED BY US TO YOU ARE PROVIDED “AS IS”, WITHOUT WARRANTY OF ANY
            KIND. WE DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
            IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, SATISFACTORY
            QUALITY AND NONINFRINGEMENT AND ALL WARRANTIES THAT MAY ARISE FROM
            COURSE OF DEALING, COURSE OF PERFORMANCE OR USAGE OF TRADE.
            Applicable law may not allow the exclusion of implied warranties, so
            the above exclusions may not apply to you. WE AND OUR AFFILIATES,
            LICENSORS, SUPPLIERS, AND AGENTS DO NOT WARRANT THAT YOUR USE OF OUR
            WEBSITE OR MATERIALS WILL BE UNINTERRUPTED, ERROR-FREE OR SECURE,
            THAT DEFECTS WILL BE CORRECTED OR THAT OUR WEBSITE, THE SERVER(S) ON
            WHICH OUR WEBSITE IS HOSTED OR OUR MATERIALS ARE FREE OF VIRUSES OR
            OTHER HARMFUL COMPONENTS. Without limiting the generality of the
            foregoing, we hereby disclaim any and all liability for any damage
            to or other impact on your equipment, hardware, software, data or
            other information or materials, whether or not caused by or related
            to (either directly or indirectly) your use of the Service,
            including but not limited to claims relating to faulty,
            malfunctioning or inoperable Service.
          </span>
          <br className="terms-of-service-text101"></br>
          <br className="terms-of-service-text102"></br>
          <span className="terms-of-service-text103">
            Limitation of Liability. IN NO EVENT WILL WE NOR ANY OF OUR
            AFFILIATES, LICENSORS SUPPLIERS OR AGENTS, NOR OUR OR THEIR
            DIRECTORS, OFFICERS, EMPLOYEES, CONSULTANTS, AGENTS OR OTHER
            REPRESENTATIVES (“INDEMNIFIED PARTIES”), BE LIABLE FOR ANY INDIRECT,
            INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES OR DAMAGES
            FOR LOSS OF PROFITS, REVENUE, BUSINESS, SAVINGS, DATA, USE OR COST
            OF SUBSTITUTE PROCUREMENT, INCURRED BY YOU OR ANY THIRD PARTY,
            WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING NEGLIGENCE) OR
            OTHERWISE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES OR IF SUCH DAMAGES ARE FORESEEABLE. YOUR SOLE REMEDY FOR
            DISSATISFACTION WITH OUR WEBSITE, MATERIALS OR ANY LINKED SITE IS TO
            STOP USING THEM. THE SOLE AND EXCLUSIVE MAXIMUM LIABILITY OF ALL
            INDEMNIFIED PARTIES COLLECTIVELY FOR ALL DAMAGES, LOSSES AND CAUSES
            OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE) OR
            OTHERWISE, SHALL BE THE TOTAL AMOUNT PAID BY YOU, IF ANY, TO ACCESS
            OR use OUR WEBSITE OR OUR MATERIALS. YOUR USE OF OUR WEBSITE AND ANY
            MATERIALS PROVIDED THROUGH OUR WEBSITE IS ENTIRELY AT YOUR OWN RISK.
            You acknowledge that the limitations of liability in these Terms and
            the allocation of risk herein are an essential element of the
            bargain between you and us, without which we would not have provided
            the Service. Our pricing reflects this allocation of risk and the
            limitation of liability specified herein.
          </span>
          <br className="terms-of-service-text104"></br>
          <br className="terms-of-service-text105"></br>
          <span className="terms-of-service-text106">
            Errors. A possibility exists that our website or materials could
            include inaccuracies or errors or information or materials that
            violate these Terms. Additionally, a possibility exists that
            unauthorized alterations could be made by third parties to our
            website applications or materials. Although we attempt to ensure the
            integrity of our website and related services, we make no guarantees
            as to its completeness or correctness. If a situation arises in
            which our platforms and website’s completeness or correctness is in
            question, please contact us via our contact information provided on
            our “Support” page of the website with, if possible, a description
            of the material to be checked and the location (URL) where such
            material can be found on our website.
          </span>
          <br className="terms-of-service-text107"></br>
          <br className="terms-of-service-text108"></br>
          <span className="terms-of-service-text109">
            If you have any questions regarding this Agreement or if you wish to
            discuss the terms of service contained herein please contact Actif3D
            by email support@Actif3D.com
          </span>
        </p>
      </section>
      <div className="terms-of-service-footer">
        <div className="terms-of-service-content">
          <div className="terms-of-service-main">
            <div className="terms-of-service-branding1">
              <Link to="/" className="terms-of-service-navlink05">
                <img
                  alt="image"
                  src="/logow.svg"
                  className="terms-of-service-image1"
                />
              </Link>
              <span className="terms-of-service-text110">
                Your Creator Economy Assets
              </span>
            </div>
            <div className="terms-of-service-links">
              <div className="terms-of-service-column">
                <span className="terms-of-service-header">
                  PRODUCTS &amp; SERVICES
                </span>
                <div className="terms-of-service-list">
                  <Link to="/download" className="terms-of-service-navlink06">
                    Actif3D Editor
                  </Link>
                  <Link to="/" className="terms-of-service-navlink07">
                    Cloud Hosting
                  </Link>
                  <a
                    href="https://editor.actif3d.com"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="terms-of-service-link2"
                  >
                    Cloud Baking
                  </a>
                  <Link to="/showcase" className="terms-of-service-navlink08">
                    <span className="terms-of-service-text111">
                      Marketplace
                    </span>
                    <br></br>
                  </Link>
                </div>
              </div>
              <div className="terms-of-service-column1">
                <span className="terms-of-service-header1">SUPPORTS</span>
                <div className="terms-of-service-list1">
                  <a
                    href="https://academy.actif3d.com"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="terms-of-service-link3"
                  >
                    Academy
                  </a>
                  <a
                    href="https://blog.actif3d.com"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="terms-of-service-link4"
                  >
                    Blog
                  </a>
                  <Link
                    to="/privacy-policy"
                    className="terms-of-service-navlink09"
                  >
                    Privacy
                  </Link>
                  <Link
                    to="/terms-of-service"
                    className="terms-of-service-navlink10"
                  >
                    Terms
                  </Link>
                  <Link to="/" className="terms-of-service-navlink11">
                    Legal
                  </Link>
                </div>
              </div>
              <div className="terms-of-service-column2">
                <span className="terms-of-service-header2">INFORMATION</span>
                <div className="terms-of-service-list2">
                  <Link to="/about" className="terms-of-service-navlink12">
                    About
                  </Link>
                  <a
                    href="https://www.linkedin.com/company/actif3d"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="terms-of-service-link5"
                  >
                    LinkedIn
                  </a>
                  <Link to="/" className="terms-of-service-navlink13">
                    Twitter
                  </Link>
                  <Link to="/" className="terms-of-service-navlink14">
                    Instagram
                  </Link>
                  <a
                    href="https://www.facebook.com/actif3d"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="terms-of-service-link6"
                  >
                    Facebook
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="terms-of-service-bottom">
            <span className="terms-of-service-text113">
              © 2023 Actif3D - All rights reserved
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TermsOfService
