import React, { useState } from "react";
import { Link } from "react-router-dom";

import Script from "dangerous-html/react";
import { Helmet } from "react-helmet";

import DataItem from "./DataItem";

import GalleryCard3 from "../components/gallery-card3";
import Check from "../components/check";
import Line from "../components/line";
import "./home.css";
import BackToTopButton from "../components/BackToTopButton";

const Home = (props) => {
  const [noOfEleme, setNoOfEleme] = useState(8);
  const slice = DataItem.items.slice(0, noOfEleme);

  const seemore = () => {
    setNoOfEleme(noOfEleme + noOfEleme);
  };

  const seeItemHome3 = slice.map((item) => {
    return (
      <a
        href={item.href}
        key={item.id}
      >
        <GalleryCard3
          image_src={item.image_src}
          title={item.title}
          subtitle={item.subtitle}
        />
      </a>
    );
  });

  return (
    <div className="home-container">
      <Helmet>
        <title>Actif3D - Your Creator Economy Assets</title>
        <meta
          name="description"
          content="A comprehensive platform for creating, trading and playing with 3D/VR/AR assets"
        />
        <meta
          property="og:title"
          content="Actif3D - Your Creator Economy Assets"
        />
        <meta
          property="og:description"
          content="A comprehensive platform for creating, trading and playing with 3D/VR/AR assets"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d75c0f3b-381e-4572-861d-fc34765a8ad7/6def6734-6147-4d8c-8f95-5278aeb9c55d?org_if_sml=1"
        />
      </Helmet>
      <section className="home-cover-video">
        <video
          src="https://presentation-website-assets.teleporthq.io/templates/togthr/togthr-video.mp4"
          loop
          muted
          poster="/hero-bg.png"
          preload="auto"
          autoPlay
          playsInline
          className="home-video"
        ></video>
        <header data-thq="thq-navbar" className="home-navbar">
          <Link to="/" className="home-navlink">
            <img alt="image" src="/logow.svg" className="home-branding" />
          </Link>
          <div data-thq="thq-burger-menu" className="home-burger-menu">
            <div className="home-hamburger" onClick={()=>{
              const element = document.getElementById("my-menu");
              element.classList.add("teleport-show");
              element.classList.add("thq-show");
              element.classList.add("thq-translate-to-default");
            }}>
              <img alt="image" src="/hamburger.svg" className="home-icon" />
            </div>
          </div>
          <div data-thq="thq-mobile-menu" id="my-menu" className="home-mobile-menu">
            <div
              data-thq="thq-mobile-menu-nav"
              data-role="Nav"
              className="home-nav"
            >
              <div className="home-container1">
                <Link to="/" className="home-navlink01">
                  <img alt="image" src="/logow.svg" className="home-image" />
                </Link>
                <div data-thq="thq-close-menu" className="home-menu-close" onClick={()=>{
                  const element = document.getElementById("my-menu");
                  element.classList.remove("teleport-show");
                  element.classList.remove("thq-show");
                  element.classList.remove("thq-translate-to-default");
                }}>
                  <svg viewBox="0 0 1024 1024" className="home-icon01">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav
                data-thq="thq-mobile-menu-nav-links"
                data-role="Nav"
                className="home-nav1"
              >
                <Link to="/about" className="home-navlink02">
                  About
                </Link>
                <a href="https://academy.actif3d.com/" className="home-link">
                  Academy
                </a>
                <a
                  href="https://pay.actif3d.com"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link01"
                >
                  Pricing
                </a>
                <Link to="/showcase" className="home-navlink03">
                  Showcase
                </Link>
                <Link to="/download" className="home-navlink04">
                  <span>Dow</span>
                  <span>nload</span>
                </Link>
              </nav>
              <div className="home-container2">
                <a
                  href="https://id.actif3d.com/signin/oauth?response_type=code&amp;client_id=actif3d&amp;redirect_uri=https://actif3d.com/auth&amp;scope=openid%20profile%20email%20name"
                  className="home-login button"
                >
                  Login
                </a>
                <a
                  href="https://id.actif3d.com/signup"
                  className="home-register button"
                >
                  Register
                </a>
              </div>
            </div>
          </div>
        </header>
        <div className="home-cover">
          <div className="home-header-container">
            <div className="home-header">
              <h1 className="home-heading">
                <span>
                  Create your own VR/AR design with
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <span className="home-text03">Actif3D Editor</span>
                <br></br>
              </h1>
            </div>
            <p className="home-caption">
              Turn architectural 3D models into online VR/AR content with
              real-time rendering in a browser
            </p>
          </div>
          <button className="home-button button">
            <a href="https://id.actif3d.com/signup" className="home-link02">
              <span>Try it now for free</span>
              <br></br>
            </a>
          </button>
        </div>
      </section>
      <section className="home-key-notes">
        <h2 className="home-caption01">
          The metaverse way for creating and selling your 3D creative contents
        </h2>
        <p className="home-caption02">
          For architects, designers, archviz artists. Bring your Creator Economy
          assets from real-estate visualizations to metaverse marketing
        </p>
      </section>
      <section className="home-statistics">
        <div className="home-content">
          <div className="home-stat">
            <h3 className="home-header01">100K+</h3>
            <span className="home-caption03">Designers joining us</span>
          </div>
          <div className="home-stat1">
            <h3 className="home-header02">Metaverse</h3>
            <span className="home-caption04">Creator Economy</span>
          </div>
          <div className="home-stat2">
            <h3 className="home-header03">10M+</h3>
            <span className="home-caption05">
              Users are playing with Actif3D assets
            </span>
          </div>
        </div>
      </section>
      <section className="home-slider">
        <div className="home-header04">
          <h2 className="home-heading01">
            <span>All-in-one 3D platform for </span>
            <span className="home-text08">Creator Economy</span>
          </h2>
        </div>
        <div className="home-selector">
          <p className="home-caption06">
            We provide design tool, collaboration tool and marketplace for
            Creator Economy in 3D/VR/AR
          </p>
          <div className="home-slide-titles">
            <div className="slide-title">
              <span>3D Creation</span>
            </div>
            <div className="slide-title slide-title-active">
              <span>Metaverse</span>
            </div>
            <div className="slide-title">
              <span>Marketplace</span>
            </div>
            <div className="slide-title">
              <span>Ecosystem</span>
            </div>
          </div>
        </div>
      </section>
      <section className="home-slides">
        <div className="home-slider1 slider blaze-slider">
          <div className="home-slider-container blaze-container">
            <div className="home-slider-track-container blaze-track-container">
              <div className="home-slider-track blaze-track">
                <div data-role="current-banner" className="banner">
                  <img
                    alt="image"
                    src="/3d-creation-400h.jpg"
                    className="home-image01"
                  />
                  <div className="home-content01">
                    <div className="home-header05">
                      <h3 className="home-heading02">3D Creation</h3>
                      <p className="home-caption07">
                        You can start with any 3D modelling software: SketchUp,
                        3ds Max, Blender, Revit...
                      </p>
                    </div>
                  </div>
                </div>
                <div data-role="current-banner" className="banner">
                  <img
                    alt="image"
                    src="/metaverse-builder-400h.jpg"
                    className="home-image02"
                  />
                  <div className="home-content02">
                    <div className="home-header06">
                      <h3 className="home-heading03">Metaverse Builder</h3>
                      <p className="home-caption08">
                        Actif3D Editor will help to transform your 3D scenes to
                        interactive metaverse content running in browsers 
                      </p>
                    </div>
                  </div>
                </div>
                <div data-role="current-banner" className="banner">
                  <img
                    alt="image"
                    src="/marketplace-400h.jpg"
                    className="home-image03"
                  />
                  <div className="home-content03">
                    <div className="home-header07">
                      <h3 className="home-heading04">Marketplace</h3>
                      <p className="home-caption09">
                        Actif3D Marketplace is a best place to sell your
                        creative products. Actif3D is a dedicated platform for
                        Creator Economy
                      </p>
                    </div>
                  </div>
                </div>
                <div data-role="current-banner" className="banner">
                  <img
                    alt="image"
                    src="/ecosystem-400h.jpg"
                    className="home-image04"
                  />
                  <div className="home-content04">
                    <div className="home-header08">
                      <h3 className="home-heading05">Ecosystem</h3>
                      <p className="home-caption10">
                        Actif3D working seamlessly with 3D designing tools,
                        metaverses and games
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="home-controls">
              <button
                data-role="previous-banner-button"
                className="home-previous blaze-prev button"
              >
                <svg viewBox="0 0 1024 1024" className="home-icon03">
                  <path d="M542.165 780.501l-225.835-225.835h494.336c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-494.336l225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-298.667 298.667c-4.096 4.096-7.168 8.789-9.259 13.824-2.176 5.205-3.243 10.795-3.243 16.341 0 10.923 4.181 21.845 12.501 30.165l298.667 298.667c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331z"></path>
                </svg>
              </button>
              <button
                data-role="next-banner-button"
                className="home-next blaze-next button"
              >
                <svg viewBox="0 0 1024 1024" className="home-icon05">
                  <path d="M481.835 243.499l225.835 225.835h-494.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h494.336l-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l298.667-298.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-298.667-298.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div>
          <Script
            html={`<script>
var el = document.querySelector('.blaze-slider')

new BlazeSlider(el, {
  all: {
    slidesToShow: 3,
    slideGap: '80px',
    loop: true,
    enableAutoplay: true,
  },
})
</script>`}
          ></Script>
        </div>
      </section>
      <section className="home-get-started">
        <div className="home-header-container1">
          <div className="home-header09">
            <h2 className="home-heading06">
              Easily share on desktop, mobile and VR
            </h2>
            <p className="home-caption11">
              Simply send a link or embed the VR scene in a website - clients
              can explore the design on any device. Create a 3D Meeting to have
              a video conversation within your virtual space
            </p>
          </div>
          <div className="home-button1">
            <button className="button">
              <a href="https://id.actif3d.com/signup" className="home-link03">
                Join us now
              </a>
            </button>
          </div>
        </div>
        <img
          alt="image"
          src="/multi-devices-800w.jpg"
          className="home-image05"
        />
      </section>
      <section className="home-objectives">
        <div className="home-content05">
          <div className="home-objectives-list">
            <div className="objective">
              <h3 className="home-text13">VR/AR Marketing</h3>
              <p className="home-text14">
                Simply send a link or embed the VR scene in a website - clients
                can explore the design on any device. Create a 3D Meeting to
                have a video conversation within your virtual space
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </p>
            </div>
            <div className="objective home-objective1">
              <h3 className="home-text15">VR/AR Presentation</h3>
              <p className="home-text16">
                Impress and boost engagement on real-estate marketing websites,
                allow buyers to virtually visit soon to be built properties
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </p>
            </div>
            <div className="objective home-objective2">
              <h3 className="home-text17">Easy to Learn</h3>
              <p className="home-text18">
                Don&apos;t worry that you will have to learn many new things.
                Actif3D integrates with popular 3D modeling tools like SketchUp,
                3ds Max, Revit. You don&apos;t have to change your design
                workflow
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="home-growth">
        <img
          alt="image"
          src="/metaverse-growth-1400w.jpg"
          className="home-image06"
        />
        <div className="home-content06">
          <div className="home-header10">
            <div className="home-header11">
              <h2 className="home-heading07">
                <span>
                  Metaverse optimized for
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <span className="home-text20">growth.</span>
                <br></br>
              </h2>
            </div>
            <p className="home-caption12">
              Metaverse is new way to boost your growth
            </p>
            <p className="home-caption13">
              It&apos;s not something strange, but from the creative work you
              are still doing
            </p>
          </div>
          <div className="home-testimonial">
            <div className="home-content07">
              <span className="home-text22">Testimonial</span>
              <p className="home-text23">
                “VR marketing has helped increase my company&apos;s marketing
                performance by 200%”
              </p>
            </div>
            <div className="home-information">
              <div className="home-author">
                <img
                  alt="image"
                  src="https://images.unsplash.com/photo-1552234994-66ba234fd567?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDN8fHBvdHJhaXR8ZW58MHx8fHwxNjcxMDU2MzMz&amp;ixlib=rb-4.0.3&amp;w=200"
                  className="home-icon07"
                />
                <span className="home-name">Andrew Gonzales</span>
              </div>
              <img alt="image" src="/logoipsum.svg" className="home-from" />
            </div>
          </div>
        </div>
      </section>
      <section className="home-experience">
        <div className="home-content08">
          <div className="home-header12">
            <div className="home-header-container2">
              <div className="home-header13">
                <h2 className="home-heading08">
                  <span>A worlds class </span>
                  <span className="home-text25">experience</span>
                </h2>
              </div>
              <p className="home-caption14">VR/AR/Metaverse experience</p>
            </div>
            <div className="home-checkmarks">
              <div className="home-check">
                <div className="home-mark">
                  <svg viewBox="0 0 1024 1024" className="home-icon08">
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="home-text26">
                  Create strong feelings about your creative products using
                  VR/AR visualization
                </span>
              </div>
              <div className="home-check1">
                <div className="home-mark1">
                  <svg viewBox="0 0 1024 1024" className="home-icon10">
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="home-text27">
                  Bridge between artistic creation and mass consumption
                </span>
              </div>
              <div className="home-check2">
                <div className="home-mark2">
                  <svg viewBox="0 0 1024 1024" className="home-icon12">
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="home-text28">
                  Especially useful when today&apos;s creative products are all
                  digitized
                </span>
              </div>
              <div className="home-check3">
                <div className="home-mark3">
                  <svg viewBox="0 0 1024 1024" className="home-icon14">
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="home-text29">
                  Easily turn your creative content into a web3 product
                </span>
              </div>
            </div>
          </div>
          <div className="home-testimonial1">
            <div className="home-content09">
              <span className="home-text30">Testimonial</span>
              <p className="home-text31">
                “My game design assets can now be easily included in any
                metaverse”
              </p>
            </div>
            <div className="home-information1">
              <div className="home-author1">
                <img
                  alt="image"
                  src="https://images.unsplash.com/photo-1618151313441-bc79b11e5090?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDEzfHxwb3RyYWl0fGVufDB8fHx8MTY3MTA1NjMzMw&amp;ixlib=rb-4.0.3&amp;w=200"
                  className="home-icon16"
                />
                <span className="home-name1">Cory Smith</span>
              </div>
              <img
                alt="image"
                src="/logo-globalverse.svg"
                className="home-from1"
              />
            </div>
          </div>
        </div>
        <img
          alt="image"
          src="/vr-experiencing-1400w.jpg"
          className="home-image07"
        />
      </section>
      <div className="home-button3">
        <button className="button">
          <Link to="/download" className="home-navlink05">
            Download Actif3D
          </Link>
        </button>
      </div>
      <div className="home-gallery">
        <h2 className="home-heading09">Marketplace</h2>
        <span className="home-text32">
          Beautiful contents from Actif3D Professional Network
        </span>
        <div className="home-container3">
          <a href="https://a3d.app/public/thao-dien-restaurant/index.html#autoplay">
            <GalleryCard3
                title="Thao Dien Restaurant"
                subtitle="Saigon Design"
                image_src="https://files.a3d.app/gallery/thao-dien-restaurant.jpg"
                rootClassName="rootClassName"
                className="home-component"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/frank-house/index.html#autoplay">
            <GalleryCard3
                title="Actif3D Sample"
                subtitle="The Actif3D Sample project"
                image_src="https://files.a3d.app/gallery/frank-house.webp"
                rootClassName="rootClassName1"
                className="home-component01"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/luxury-apartment/index.html#autoplay">
            <GalleryCard3
                title="Luxury Apartment"
                subtitle="An Luxury Apartment"
                image_src="https://files.a3d.app/gallery/luxury-apartment.webp"
                rootClassName="rootClassName3"
                className="home-component02"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/a3d-sample/index.html#autoplay">
            <GalleryCard3
                subtitle="A3D Sample"
                image_src="https://files.a3d.app/gallery/a3dsample.jpg"
                rootClassName="rootClassName2"
                className="home-component03"
            ></GalleryCard3>
          </a>
          <a href="https://s2.citiverse.io#autoplay">
            <GalleryCard3
                title="Modern Apartment"
                subtitle="By Allis Design"
                image_src="https://files.a3d.app/gallery/h3dapartment.webp"
                rootClassName="rootClassName4"
                className="home-component04"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080171/index.html#autoplay">
            <GalleryCard3
                title="The Lake House"
                subtitle="Bao Quoc"
                image_src="https://a3d.app/public/gallery/vlu/2175801080171/2023-07-07-20-32-23/cover.jpg"
                rootClassName="rootClassName5"
                className="home-component05"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080121/index.html#autoplay">
            <GalleryCard3
                title="Dark House"
                subtitle="Bao Hoa"
                image_src=" https://a3d.app/public/gallery/vlu/2175801080121/2023-07-07-19-49-57/cover.jpg"
                rootClassName="rootClassName6"
                className="home-component06"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080252/index.html#autoplay">
            <GalleryCard3
                title="Deep Purple"
                subtitle="Nhat Vy"
                image_src="https://a3d.app/public/gallery/vlu/2175801080252/2023-07-08-12-20-56/cover.jpg"
                rootClassName="rootClassName7"
                className="home-component07"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080145/index.html#autoplay">
            <GalleryCard3
                title="Myths Pagoda"
                subtitle="Thanh Phong"
                image_src=" https://a3d.app/public/gallery/vlu/2175801080145/2023-07-07-20-26-00/cover.jpg"
                rootClassName="rootClassName8"
                className="home-component08"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080075/index.html#autoplay">
            <GalleryCard3
                title="Haru Kaori"
                subtitle="Huong Xuan"
                image_src="https://a3d.app/public/gallery/vlu/2175801080075/2023-07-07-17-53-19/cover.jpg"
                rootClassName="rootClassName9"
                className="home-component09"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080287/index.html#autoplay">
            <GalleryCard3
                title="Holiday Place"
                subtitle="Uyen Nhi"
                image_src="https://a3d.app/public/gallery/vlu/2175801080287/2023-07-08-12-34-24/cover.jpg"
                rootClassName="rootClassName10"
                className="home-component10"
            ></GalleryCard3>
          </a>
          <a href="https://a3d.app/public/gallery/vlu/2175801080082/index.html#autoplay">
            <GalleryCard3
                title="My Smallville"
                subtitle="Uyen Sam"
                image_src=" https://a3d.app/public/gallery/vlu/2175801080082/2023-07-07-18-04-11/cover.jpg"
                rootClassName="rootClassName11"
                className="home-component11"
            ></GalleryCard3>
          </a>
        </div>
        <Link to="/showcase" onClick={()=> {document.documentElement.scrollTop = 0}} className="showcase-navlink05 button" >
          See more...
        </Link>
      </div>
      <section className="home-pricing">
        <div className="home-header-container3">
          <div className="home-header14">
            <h2 className="home-heading10">
              <span>Plan &amp; Price</span>
              <br></br>
            </h2>
          </div>
          <p className="home-caption15">Choose your suitable option</p>
        </div>
        <div className="home-table">
          <div className="home-row">
            <div className="home-headers">
              <span className="home-text35">Placeholder</span>
            </div>
            <div className="home-plans">
              <div className="home-row01">
                <span className="home-text36">BegiNner</span>
                <span className="home-text37">PROFESSIONAL</span>
                <span className="home-text38">DEVELOPER</span>
              </div>
            </div>
          </div>
          <div className="home-row02">
            <div className="home-headers01">
              <span className="home-text39">Placeholder</span>
            </div>
            <div className="home-plans01">
              <div className="home-row03">
                <span className="home-text40">179 USD/year</span>
                <span className="home-text41">
                  349 USD/year
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <span className="home-text42">
                  999USD/year
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="home-row04">
            <div className="home-headers02">
              <span className="home-text43">Actif3D Editor</span>
            </div>
            <div className="home-plans02">
              <div className="home-row05">
                <div className="value">
                  <Check></Check>
                </div>
                <div className="value">
                  <Check></Check>
                </div>
                <div className="value">
                  <Check></Check>
                </div>
              </div>
            </div>
          </div>
          <div className="home-row06">
            <div className="home-headers03">
              <span className="home-text44">Local hosting scenes</span>
            </div>
            <div className="home-plans03">
              <div className="home-row07">
                <div className="value">
                  <Check></Check>
                </div>
                <div className="value">
                  <Check></Check>
                </div>
                <div className="value">
                  <Check></Check>
                </div>
              </div>
            </div>
          </div>
          <div className="home-row08">
            <div className="home-headers04">
              <span className="home-text45">Cloud hosting scenes</span>
            </div>
            <div className="home-plans04">
              <div className="home-row09">
                <div className="value">
                  <span className="home-text46">5 scenes</span>
                </div>
                <div className="value">
                  <span className="home-text47">50scenes</span>
                </div>
                <div className="value">
                  <span className="home-text48">150 scenes</span>
                </div>
              </div>
            </div>
          </div>
          <div className="home-row10">
            <div className="home-headers05">
              <span className="home-text49"> SketchUp plugin</span>
            </div>
            <div className="home-plans05">
              <div className="home-row11">
                <div className="value">
                  <span className="home-text50">Basic</span>
                </div>
                <div className="value">
                  <span className="home-text51">Advanced</span>
                </div>
                <div className="value">
                  <span className="home-text52">Advanced</span>
                </div>
              </div>
            </div>
          </div>
          <div className="home-row12">
            <div className="home-headers06">
              <span className="home-text53">Web site embedding</span>
            </div>
            <div className="home-plans06">
              <div className="home-row13">
                <div className="value">
                  <Line></Line>
                </div>
                <div className="value">
                  <Check></Check>
                </div>
                <div className="value">
                  <Check></Check>
                </div>
              </div>
            </div>
          </div>
          <div className="home-row14">
            <div className="home-headers07">
              <span className="home-text54">Your brand&apos;s logo</span>
            </div>
            <div className="home-plans07">
              <div className="home-row15">
                <div className="value">
                  <Line></Line>
                </div>
                <div className="value">
                  <Check></Check>
                </div>
                <div className="value">
                  <Check></Check>
                </div>
              </div>
            </div>
          </div>
          <div className="home-row16">
            <div className="home-headers08">
              <span className="home-text55">HTML/JavaScript API</span>
            </div>
            <div className="home-plans08">
              <div className="home-row17">
                <div className="value">
                  <Line></Line>
                </div>
                <div className="value">
                  <Line></Line>
                </div>
                <div className="value">
                  <Check></Check>
                </div>
              </div>
            </div>
          </div>
          <div className="home-row18">
            <div className="home-headers09">
              <a href="https://pay.actif3d.com" className="home-link10 button">
                See more...
              </a>
            </div>
            <div className="home-plans09">
              <div className="home-row19"></div>
            </div>
          </div>
        </div>
        <a href="https://pay.actif3d.com" className="home-link11 button">
          Buy now
        </a>
      </section>
      <section className="home-faq">
        <div className="home-header15">
          <h2 className="home-heading11">
            <span>Frequently asked </span>
            <span className="home-text57">questions</span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
          </h2>
        </div>
        <div className="home-content10">
          <div className="home-column">
            <div className="home-element">
              <h3 className="home-header16">
                Can I do 3D modelling directly in Actif3D?
              </h3>
              <p className="home-content11">
                We don&apos;t support traditional 3D modelling feature. But we
                are releasing AI Prompt feature soon, which can let you create
                3D models using text-to-3D
              </p>
            </div>
            <div className="home-element1">
              <h3 className="home-header17">
                Does Actif3D keep V-Ray or Corona lights when import from
                SketchUp?
              </h3>
              <p className="home-content12">
                Currently you have to remove V-Ray or other render engine&apos;s
                lights, and re-add them in Actif3D Editor. But we&apos;ll make
                that automated in the next release of SketchUp and 3ds Max
                plugins
              </p>
            </div>
            <div className="home-element2">
              <h3 className="home-header18">
                Do I need to provide credit card for trial version? What are the
                limitations of the Trial? 
              </h3>
              <p className="home-content13">
                No credit card required for trial version. The free trial
                version gives access to almost all features of Actif3D,  it is
                active only for 30 days and can not be used for commercial
                purposes
              </p>
            </div>
          </div>
          <div className="home-column1">
            <div className="home-element3">
              <h3 className="home-header19">
                How many tris for a scene Actif3D can handle? Do I have to
                optimize the texture size?
              </h3>
              <p className="home-content14">
                There&apos;s no scene size limitation and the actual limit
                depends on your computer hardware. But we recommend you keep the
                number of triangles in your scene below 5 million to render it
                well on mobile devices
              </p>
            </div>
            <div className="home-element4">
              <h3 className="home-header20">
                Which VR devices and software I have to use for VR experiencing?
              </h3>
              <p className="home-content15">
                You can use Oculus Rift, HTC Vive with Chrome or Firefox desktop
                browsers; Oculus Quest and Oculus Go should work with Oculus
                Browser; Windows Mixed Reality headsets should work
                with Microsoft Edge; Google Cardboard works with all mobile
                browsers with WebGL enabled. And much more...
              </p>
            </div>
            <div className="home-element5">
              <h3 className="home-header21">
                Can I use my custom domain? Can I host scenes on my own server?
              </h3>
              <p className="home-content16">
                With Professional Plan or higher, you can use your custom
                domain. You can export html bundle and host your scenes on your
                own server with Developer Plan
              </p>
              <a
                href="https://academy.actif3d.com/faq"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link12 button"
              >
                See more...
              </a>
            </div>
          </div>
        </div>
      </section>
      <div className="home-footer">
        <div className="home-content17">
          <div className="home-main">
            <div className="home-branding1">
              <Link to="/" className="home-navlink06">
                <img alt="image" src="/logow.svg" className="home-image08" />
              </Link>
              <span className="home-text59">Your Creator Economy Assets</span>
            </div>
            <div className="home-links">
              <div className="home-column2">
                <span className="home-header22">PRODUCTS &amp; SERVICES</span>
                <div className="home-list">
                  <Link to="/download" className="home-navlink07">
                    Actif3D Editor
                  </Link>
                  <Link to="/" className="home-navlink08">
                    Cloud Hosting
                  </Link>
                  <a
                    href="https://editor.actif3d.com"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link13"
                  >
                    Cloud Baking
                  </a>
                  <Link to="/showcase" className="home-navlink09">
                    <span className="home-text60">Marketplace</span>
                    <br></br>
                  </Link>
                </div>
              </div>
              <div className="home-column3">
                <span className="home-header23">SUPPORTS</span>
                <div className="home-list1">
                  <a href="https://academy.actif3d.com" className="home-link14">
                    Academy
                  </a>
                  <a href="https://blog.actif3d.com" className="home-link15">
                    Blog
                  </a>
                  <Link to="/privacy-policy" className="home-navlink10">
                    Privacy
                  </Link>
                  <Link to="/terms-of-service" className="home-navlink11">
                    Terms
                  </Link>
                  <Link to="/" className="home-navlink12">
                    Legal
                  </Link>
                </div>
              </div>
              <div className="home-column4">
                <span className="home-header24">INFORMATION</span>
                <div className="home-list2">
                  <Link to="/about" className="home-navlink13">
                    About
                  </Link>
                  <a
                    href="https://www.linkedin.com/company/actif3d"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link16"
                  >
                    LinkedIn
                  </a>
                  <Link to="/" className="home-navlink14">
                    Twitter
                  </Link>
                  <Link to="/" className="home-navlink15">
                    Instagram
                  </Link>
                  <a
                    href="https://www.facebook.com/actif3d"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link17"
                  >
                    Facebook
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="home-bottom">
            <span className="home-text62">
              © 2023 Actif3D - All rights reserved
            </span>
            <BackToTopButton />
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Home;
