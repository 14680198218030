import { useEffect, useState } from "react";

function BackToTopButton() {
  const [backToTopButton, setBackToTopButton] = useState(false);
  const [mounted, setMounted] = useState(true);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (mounted && window.scrollY > 100) {
        setBackToTopButton(true);
      } else {
        setBackToTopButton(false);
      }
    });
    return () => setMounted(false);
  }, []);

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {backToTopButton && (
        <button
          data-role="scroll-top"
          className="home-button5 button"
          onClick={scrollUp}
        >
          <img alt="image" src="/arrow.svg" className="home-image09" />
        </button>
      )}
    </>
  );
}

export default BackToTopButton;
